import React from "react";

const SignoutIcon = ({ color = "#BDBDBD" }) => {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clip-path='url(#clip0_524_585)'>
        <path
          d='M7.14355 0.151855V8.57997H8.60931V1.61761H22.534V22.3825H8.60931V15.4202H7.14355V23.8483H23.9998V0.151855H7.14355Z'
          fill={color}
        />
        <path
          d='M15.5719 12.7329V11.2672H2.80581L4.4862 9.58673L3.44976 8.55029L0 12.0001L3.44976 15.4498L4.4862 14.4133L2.80576 12.7329H15.5719Z'
          fill={color}
        />
      </g>
      <defs>
        <clipPath id='clip0_524_585'>
          <rect width='24' height='24' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SignoutIcon;
