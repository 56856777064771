import * as yup from "yup";

export const SignUpValidationSchema = yup.object().shape({
  firstName: yup.string().required("Please Enter first name"),
  lastName: yup.string().required("Please Enter last name"),
  phoneNumber: yup.string().required("Please Enter phone no"),
  // email: yup
  //   .string()
  //   .required("Please Enter your Email")
  //   .matches(
  //     /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
  //     "Invalid email format"
  //   ),
  gender: yup.string().required("Please Select Gender"),
  password: yup
    .string()
    .required("Please create a password")
    .min(8, "Minimum length of 8 characters")
    .matches(/[A-Z]/, "Must contain at least one uppercase letter")
    .matches(/[0-9]/, "Must contain at least one number")
    .matches(
      /[!@#$%^&*(),.?":{}|<>]/,
      "Must contain at least one special character"
    ),
  confirmPassword: yup
    .string()
    .required("Pleas enter confirm password")
    .oneOf([yup.ref("password"), null], "Passwords must match"),
});

export const LoginValidationSchema = yup.object().shape({
  email: yup
    .string()
    .required("Please Enter your Email")
    .matches(
      /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
      "Invalid email format"
    ),
  password: yup.string().required("Please enter a password"),
});

export const ResetPasswordValidationSchema = yup.object().shape({
  password: yup.string().required("Please enter a password"),

  confirmPassword: yup
    .string()
    .required("Please confirm your password")
    .oneOf([yup.ref("password"), null], "Passwords must match"),
});
