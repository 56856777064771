import React from "react";

const UserIcon = ({ color = "#BDBDBD" }) => {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clip-path='url(#clip0_54_351)'>
        <path
          d='M8.8002 0C5.70767 0 3.2002 2.50507 3.2002 5.59686C3.2002 8.68866 5.70767 11.1937 8.8002 11.1937C11.8927 11.1937 14.4002 8.68866 14.4002 5.59686C14.4002 2.50507 11.8927 0 8.8002 0Z'
          fill={color}
        />
        <path
          d='M5.6 14.3906C2.50738 14.3906 0 16.8968 0 19.9898V23.9854H17.6V19.9898C17.6 16.8968 15.0926 14.3906 12 14.3906H5.6Z'
          fill={color}
        />
        <path
          d='M20.0002 16H19.2002V24H24.0002V20C24.0002 17.7909 22.2093 16 20.0002 16Z'
          fill={color}
        />
        <path
          d='M18.4004 6.3999C16.1913 6.3999 14.4004 8.19077 14.4004 10.3999C14.4004 12.609 16.1913 14.3999 18.4004 14.3999C20.6095 14.3999 22.4004 12.609 22.4004 10.3999C22.4004 8.19077 20.6095 6.3999 18.4004 6.3999Z'
          fill={color}
        />
      </g>
      <defs>
        <clipPath id='clip0_54_351'>
          <rect width='24' height='24' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
};

export default UserIcon;
