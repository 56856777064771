import baseApi from "../../../Lib/api/baseApi";
import endpoints from "../../../Lib/api/endpoints";

export const AuthApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getAllAudit: builder.query({
      query: () => ({
        ...endpoints.auditLog,
      }),
      transformResponse: (response) => {
        return response?.data?.data?.map((d) => ({
          initiator: `${d.admin.firstName} ${d.admin.lastName}`,
          description: d.description,
          action: d.action,
          dateAdded: d.createdAt,
          id: d.id,
        }));
      },
    }),
  }),
});

export const { useGetAllAuditQuery } = AuthApi;
