import React, { useState } from "react";
import { images } from "../../Assets";
import { format, parseISO } from "date-fns";
import { Button } from "../../Components/ui/button";

const RiderProfileIndex = ({ data }) => {
  return (
    <div className='w-full h-auto mt-5 bg-white rounded-md p-2 shadow-md'>
      <div className='mb-2'>
        <h3 className='text-lg font-medium p-2 m-2 text-left mb-2 text-primaryBlack'>
          Profile
        </h3>
      </div>
      <div className='border-b-2 border-gray-100 mb-5'></div>
      <div className='mt-5'>
        <div className='mt-5 grid md:grid-cols-3 gap-7 p-4'>
          <div>
            <p className='text-sm text-gray-400 font-medium'>Username</p>
            <p className='text-primaryBlack font-normal text-base'>
              {data?.username || "N/A"}
            </p>
          </div>
          <div>
            <p className='text-sm text-gray-400 font-medium'>Email</p>
            <p className='text-primaryBlack font-normal text-base'>
              {data?.email || "N/A"}
            </p>
          </div>
          <div>
            <p className='text-sm text-gray-400 font-medium'>Phone Number</p>
            <p className='text-primaryBlack font-normal text-base'>
              {data?.phoneNumber || "N/A"}
            </p>
          </div>
          <div>
            <p className='text-sm text-gray-400 font-medium'>Gender</p>
            <p className='text-primaryBlack font-normal text-base'>
              {data?.gender || "N/A"}
            </p>
          </div>
          <div>
            <p className='text-sm text-gray-400 font-medium'>Date of Birth</p>
            <p className='text-primaryBlack font-normal text-base'>
              {data?.dateOfBirth || "N/A"}
            </p>
          </div>

          <div>
            <p className='text-sm text-gray-400 font-medium'>Vehicle Type</p>
            <p className='text-primaryBlack font-normal text-base'>
              Motorcycle
            </p>
          </div>
          <div>
            <p className='text-sm text-gray-400 font-medium'>
              License Plate Number
            </p>
            <p className='text-primaryBlack font-normal text-base'>FKJ-254XA</p>
          </div>
          <div>
            <p className='text-sm text-gray-400 font-medium'>Address</p>
            <p className='text-primaryBlack font-normal text-base'>
              {data?.address || "N/A"}
            </p>
          </div>
        </div>
      </div>

      <div className=' p-4'>
        <h4 className='text-primaryBlack mb-2 font-semibold'>
          Linked Documents
        </h4>
        <div className='mt-5 grid md:grid-cols-2 gap-5 p-2'>
          {data?.documents &&
            data?.documents.map((doc, index) => (
              <a
                key={index}
                className='border border-gray-300 rounded-md  w-full cursor-pointer'
                href={`${process.env.REACT_APP_BASE_URL}file/asset/${doc?.filename}`}
                target='_blank'
                rel='noopener noreferrer'
              >
                <div className='flex justify-between items-center p-2'>
                  <div className='flex gap-2'>
                    <img
                      src={images.filetype}
                      className=''
                      alt='nownow rider'
                    />
                    <div className='space-y-1'>
                      <p className='md:text-sm  text-primaryBlack font-semibold'>
                        {doc.name}
                      </p>
                      <p className='text-xs font-normal text-primaryAsh'>
                        Expiry Date:{" "}
                        {format(parseISO(doc?.expiryDate), "LLL dd, y")}
                      </p>
                    </div>
                  </div>
                  <div className=''>
                    <img src={images.upload} className='' alt='nownow rider' />
                  </div>
                </div>
              </a>
            ))}
        </div>
      </div>
    </div>
  );
};

export default RiderProfileIndex;
