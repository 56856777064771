import React, { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { images } from "../../Assets";
import { ArrowLeftIcon } from "@radix-ui/react-icons";
import OTPSixInput from "./OTPSixInput";
import { Button } from "../../Components/ui/button";
import { useResendOtpMutation, useVerifyOtpMutation } from "./AuthApi";
import toast from "react-hot-toast";
import Spinner from "../../Assets/spinner";

const OtpAuthentication = () => {
  const navigate = useNavigate();
  const [postOtp] = useVerifyOtpMutation();
  const [resendOtp] = useResendOtpMutation();
  const location = useLocation();
  const [otp, setOtp] = useState("");
  const [submitLoading, setSubmitLoading] = useState(false);

  const handleSubmitOTP = async () => {
    if (otp.length !== 6) {
      return toast.error("Please Input OTP", { id: "otp" });
    }
    setSubmitLoading(true);
    try {
      const { data } = await postOtp({ otp, email: location.state });
      if (data.statusCode === 200) {
        setSubmitLoading(false);
        toast.success(data?.message, { id: "fpo" });
        navigate("/reset-password", {
          state: { email: data?.data?.email, otp },
        });
      }
    } catch (error) {
      setSubmitLoading(false);
    }
  };

  const handleResendOtp = async () => {
    try {
      const { data } = await resendOtp({ email: location.state });
      if (data?.statusCode === 200) {
        toast.success(data?.message, { id: "staus" });
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className='h-[100vh]'>
      <div className='md:flex items-center h-full justify-between'>
        <div className='w-[45%] hidden md:block mb-2 p-10 '>
          <img
            src={images.officeSpace}
            className='w-full h-auto'
            alt='nownow rider'
          />
        </div>

        <div className='w-full md:w-[50%] px-10 text-center mb-10 mt-0'>
          <div className='mb-10 flex justify-center'>
            <img
              src={images.nownow}
              className='w-[40%] h-auto'
              alt='nownow rider'
            />
          </div>
          <p className='text-md font-normal text-left mt-5 md:text-center'>
            {`Enter verification code sent to ${location.state}`}
          </p>
          <div className='mt-10'>
            {/* Render OTPSixInput component */}
            <OTPSixInput onComplete={setOtp} />
            <div className='mt-8'>
              <button
                type='submit'
                className='bg-primaryGreen flex justify-center mx-auto outline-none text-white w-[50%] py-2 rounded-md'
                disabled={submitLoading}
                onClick={handleSubmitOTP}
              >
                {submitLoading ? <Spinner /> : "Verify"}
              </button>
              <p className='mt-5'>
                Didn't get this code?{" "}
                <Button
                  onClick={handleResendOtp}
                  className=' text-primaryGreen'
                  variant='ghost'
                >
                  Resend
                </Button>
              </p>
            </div>
            <div className='flex items-center justify-center mt-4'>
              <Link to='/login' className='text-black flex items-center'>
                <ArrowLeftIcon className='h-5 w-5 mr-1 text-primaryGreen' />
                Back to Sign In
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OtpAuthentication;
