import React, { useEffect, useState } from "react";
import DataTable from "../../Components/ui/dataTable";
import { DatePickerWithRange } from "../../Components/ui/dateRange";
import { images } from "../../Assets";
import { parseMoney } from "../../Lib/utils";
import { Button } from "../../Components/ui/button";
import NavTabs from "../../Components/ui/tabs";
import { NavLink } from "react-router-dom";
import { useGetAllRidersQuery } from "./RiderApi";
import { format, parseISO } from "date-fns";
import { SkeletonTable } from "../../Components/ui/skeleton";

export const columns = [
  // {
  //   accessorKey: "no",
  //   header: "No",
  //   cell: ({ row }) => <div>{row.getValue("no")}</div>,
  // },
  {
    accessorKey: "name",
    header: "Name",
    cell: ({ row }) => (
      <div className='font-semibold'>{row.getValue("name")}</div>
    ),
  },
  {
    accessorKey: "createdAt",
    header: "Reg. Date",
    cell: ({ row }) => (
      <div className='capitalize'>
        {format(parseISO(row.getValue("createdAt")), "LLL dd, y")}
      </div>
    ),
  },
  {
    accessorKey: "phoneNumber",
    header: "Phone Number",
    cell: ({ row }) => (
      <div className='lowercase'>{row.getValue("phoneNumber")}</div>
    ),
  },
  {
    accessorKey: "email",
    header: "Email",
    cell: ({ row }) => <div className='lowercase'>{row.getValue("email")}</div>,
  },
  {
    accessorKey: "status",
    header: "Status",
    cell: ({ row }) => {
      if (row.original?.status === "pending") {
        return (
          <Button asChild className='w-full' variant='pending'>
            <p>Pending</p>
          </Button>
        );
      }
      if (row.original?.status === "approved") {
        return (
          <Button asChild className='w-full' variant='successful'>
            <p>Active</p>
          </Button>
        );
      }
    },
  },
  {
    id: "action",
    cell: ({ row }) => {
      const details = row.original;
      return (
        <NavLink
          to={`/riders/profile/${details.id}`}
          className='whitespace-nowrap border border-primaryGreen text-primaryGreen p-2 rounded-md font-medium'
        >
          View Details
        </NavLink>
      );
    },
  },
];
const Riders = () => {
  const { data, isLoading: loading } = useGetAllRidersQuery("", {
    refectchOnFocus: true,
    refetchOnReconnect: true,
  });

  const tabs = [
    { name: "Pending Approvals", id: "pending" },
    { name: "Active Riders", id: "active" },
    { name: "Declined Riders", id: "decline" },
  ];
  const [search, setSearch] = useState("");
  const [activeTab, setActiveTab] = useState("pending");
  const [newData, setNewData] = useState([]);

  useEffect(() => {
    try {
      if (loading === false) {
        switch (activeTab) {
          case "active":
            // Filter users with status "approved"
            const approvedUsers = data?.filter(
              (user) => user.status === "approved"
            );
            setNewData(approvedUsers);
            break;

          case "pending":
            // Filter users with status "pending"
            const pendingUsers = data?.filter(
              (user) => user.status === "pending"
            );
            setNewData(pendingUsers);
            break;

          case "decline":
            // Filter users with status "declined"
            const declinedUsers = data?.filter(
              (user) => user.status === "declined"
            );
            setNewData(declinedUsers);
            break;

          default:
            // Default behavior if none of the above cases match
            setNewData([]);
            break;
        }
      }
    } catch (error) {}
  }, [loading, activeTab, data]);

  return (
    <div className='bg-white p-4'>
      <NavTabs tabs={tabs} activeTab={activeTab} setActiveTab={setActiveTab} />
      <div className='flex justify-between items-center my-4'>
        {/* <div className='flex items-center bg-[#F5FEF1] w-[200px] p-1 rounded-sm'>
            <input
              id='firstName'
              className='bg-transparent w-[90%] px-1 outline-none placeholder:text-sm'
              value={search}
              placeholder='Search'
              onChange={(e) => setSearch(e.target.value)}
            />
            <img src={images.search} className='h-5 w-5' />
          </div> */}
        <div />
      </div>
      {/* {JSON.stringify(data, null, 2)} */}
      <div className='max-h-[500px] overflow-hidden hover:overflow-auto  max-w-full rounded-md border'>
        <div className='w-full'>
          {loading ? (
            <SkeletonTable />
          ) : (
            <DataTable columns={columns} data={newData} />
          )}
        </div>
      </div>
    </div>
  );
};

export default Riders;
