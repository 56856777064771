import React, { useState } from "react";
import { DatePickerWithRange } from "../../Components/ui/dateRange";
import DataTable from "../../Components/ui/dataTable";
import { Button } from "../../Components/ui/button";
import { images } from "../../Assets";
import { parseMoney } from "../../Lib/utils";
import { Modal, ModalContent, ModalTrigger } from "../../Components/ui/modal";
import { useGetAllOrdersQuery } from "./OrderApi";
import { SkeletonTable } from "../../Components/ui/skeleton";
import Pagination from "../../Components/ui/pagination";
import OrderDetails from "./orderDetails";
import { format } from "date-fns";

const Orders = () => {
  const today = new Date();
  const [date, setDate] = useState({
    from: today,
    to: today,
  });
  const [page, setPage] = useState(1);
  const { data, isLoading: loading } = useGetAllOrdersQuery(
    {
      page,
      startDate: format(date?.from || new Date(), "yyy-MM-dd"),
      endDate: format(date?.to || new Date(), "yyy-MM-dd"),
    },
    {
      refectchOnFocus: true,
      refetchOnReconnect: true,
      refetchOnMountOrArgChange: true,
    }
  );
  const columns = [
    {
      accessorKey: "no",
      header: "No",
      cell: ({ row }) => (
        <div className='capitalize w-[60px]'>{row.getValue("no")}</div>
      ),
    },
    {
      accessorKey: "amount",
      header: "Amount",
      cell: ({ row }) => (
        <div className='font-semibold'>
          {parseMoney(row.getValue("amount"))}
        </div>
      ),
    },
    {
      accessorKey: "customerName",
      header: "Customer Name",
      cell: ({ row }) => (
        <div className='capitalize'>{row.getValue("customerName")}</div>
      ),
    },
    {
      accessorKey: "customerPhone",
      header: "Customer Phone",
      cell: ({ row }) => (
        <div className='capitalize'>{row.getValue("customerPhone")}</div>
      ),
    },
    {
      accessorKey: "item",
      header: "Item",
      cell: ({ row }) => (
        <div className='capitalize w-[100px]'>{row.getValue("item")}</div>
      ),
    },
    // {
    //   accessorKey: "status",
    //   header: "Status",
    //   cell: ({ row }) => {
    //     if (row.getValue("status") === "delivered") {
    //       return (
    //         <Button asChild variant='successful' className='w-full'>
    //           <p>Delivered</p>
    //         </Button>
    //       );
    //     }
    //     if (row.getValue("status") === "failed") {
    //       return (
    //         <Button asChild variant='failed' className='w-full'>
    //           <p>Rejeccted</p>
    //         </Button>
    //       );
    //     }
    //   },
    // },
    {
      id: "action",
      cell: ({ row }) => {
        const detailsId = row.original.id;
        return (
          <Modal>
            <ModalContent name='Order Details'>
              <OrderDetails id={detailsId} />
            </ModalContent>
            <ModalTrigger>
              <Button
                variant='outline'
                className='outline-none focus:outline-none focus-visible:outline-none px-4 whitespace-nowrap'
              >
                View Details
              </Button>
            </ModalTrigger>
          </Modal>
        );
      },
    },
  ];
  return (
    <div className='bg-white p-4'>
      <div className='flex justify-between items-center my-4'>
        <div className='flex items-center bg-[#F5FEF1] w-[200px] p-1 rounded-sm'>
          {/* <input
            id='firstName'
            className='bg-transparent w-[90%] px-1 outline-none placeholder:text-sm'
            value={search}
            placeholder='Search'
            onChange={(e) => setSearch(e.target.value)}
          />
          <img src={images.search} className='h-5 w-5' /> */}
        </div>
        <DatePickerWithRange date={date} setDate={setDate} />
      </div>
      <div className='max-h-[80vh] overflow-hidden hover:overflow-auto  max-w-full rounded-md border'>
        <div className='w-full'>
          {loading ? (
            <SkeletonTable />
          ) : (
            <DataTable columns={columns} data={data?.data} />
          )}
        </div>
      </div>
      {!loading && (
        <Pagination
          page={page}
          limit={data?.limit ?? 0}
          total={data?.total ?? 0}
          setPage={setPage}
        />
      )}
    </div>
  );
};

export default Orders;
