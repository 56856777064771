import * as React from "react";
import { Slot } from "@radix-ui/react-slot";
import { cva } from "class-variance-authority";

import { cn } from "../../Lib/utils";

const buttonVariants = cva(
  "inline-flex items-center justify-center rounded-md text-sm font-medium disabled:pointer-events-none disabled:opacity-50 ",
  {
    variants: {
      variant: {
        default: "bg-primaryGreen text-white hover:bg-primaryGreen/90",
        accepted: "bg-primaryGreen text-white hover:bg-primaryGreen/90",
        failed: "bg-[#F4B7B5] text-[#590C07]",
        declined: "bg-[#F4B7B5] text-[#590C07]",
        expired: "bg-[#F4B7B5] text-[#590C07]",
        outline: "border border-green text-green bg-transparent",
        successful: "bg-[#BEF2B9] border border-[#BEF2B9] text-[#163C29]",
        delivered: "bg-[#BEF2B9] border border-[#BEF2B9] text-[#163C29]",
        in_progress: "bg-[#BEF2B9] border border-[#BEF2B9] text-[#163C29]",
        picked_up: "bg-[#BEF2B9] border border-[#BEF2B9] text-[#163C29]",
        pending: "bg-[#FEF6CF] text-[#6B2B0D]",
        cancelled: "bg-[#3A3A3A]/20 text-black",
        ghost: "bg-transparent",
        link: "text-slate-900 underline-offset-4 hover:underline dark:text-slate-50",
      },
      size: {
        default: "h-10 px-2 py-1",
        sm: "h-9 rounded-md px-3",
        lg: "h-11 rounded-md px-8",
        icon: "h-10 w-10",
      },
    },
    defaultVariants: {
      variant: "default",
      size: "default",
    },
  }
);

const Button = React.forwardRef(
  ({ className, variant, size, asChild = false, ...props }, ref) => {
    const Comp = asChild ? Slot : "button";
    return (
      <Comp
        className={cn(buttonVariants({ variant, size, className }))}
        ref={ref}
        {...props}
      />
    );
  }
);
Button.displayName = "Button";

export { Button, buttonVariants };
