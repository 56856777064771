import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import { GoogleMap, MarkerF, CircleF } from "@react-google-maps/api";
import { SkeletonTable } from "./ui/skeleton";
import { useSelector } from "react-redux";
import WithGoogleMapsLoader from "./WithGoogleMapsLoader"; // HOC for loading maps script

const MapComponentBeta = ({ enRoute }) => {
  const location = useSelector((state) => state.location.driverLocation);
  const driverLocation = useSelector((state) => state.location.driverRoute);
  const liveLocationIcon = {
    path: window.google.maps.SymbolPath.CIRCLE,
    fillColor: "#91B156",
    fillOpacity: 2,
    scale: 8,
    strokeColor: "#192B0C",
    strokeWeight: 3,
    // Custom CSS animation class
    className: "pulsating-dot",
  };
  const circleOptions = {
    strokeColor: "#007bff",
    strokeOpacity: 0.8,
    strokeWeight: 2,
    fillColor: "#007bff",
    fillOpacity: 0.35,
    clickable: false,
    draggable: false,
    editable: false,
    visible: true,
    radius: 8, // Radius in meters
    zIndex: 1,
  };
  // Use useMemo to prevent unnecessary re-renders
  const position = useMemo(() => location, [location]);
  const driverPosition = useMemo(() => driverLocation, [driverLocation]);

  return (
    <div className='Map'>
      <GoogleMap
        mapContainerClassName='h-full w-full'
        center={enRoute || position}
        zoom={enRoute ? 18 : 15}
      >
        <MarkerF
          position={enRoute || position}
          icon={enRoute && liveLocationIcon}
        />
      </GoogleMap>
    </div>
  );
};

// Wrapping the component with the HOC
export default WithGoogleMapsLoader(memo(MapComponentBeta));
