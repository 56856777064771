import arrowDown from "./arrow-down.svg";
import arrowUp from "./arrow-up.svg";
import dashboard from "./dashboard.svg";
import navBarArrowDown from "./navbar-arrow-down.svg";
import navBarArrowUp from "./navbar-arrow-up.svg";
import defaulters from "./defaulters.svg";
import threeDots from "./threeDots.svg";
import rider from "./rider.png";
import nownow from "./nownow.png";
import officeSpace from "./officeSpace.jpg";
import search from "./search.svg";
import oodia from "./oodia.png";
import check from "./check.png";
import order from "./order.svg";
import earnings from "./earnings.svg";
import recentOrder from "./recent-order.svg";
import personLine from "./person.svg";
import percentUp from "./percentUp.svg";
import closeBtn from "./closeBtn.svg";
import trackMap from "./trackMap.svg";
import location from "./location.png";
import line77 from "./line77.svg";
import pickup from "./pickup.svg";
import dropOff from "./dropOff.svg";
import line167 from "./line167.png";
import profilePix from "./profilePix.svg";
import motocycle from "./motocycle.svg";
import filetype from "./filetype.svg";
import fileUpload from "./fileUpload.png";
import upload from "./upload.svg";
import copy from "./copy.svg";
import pay from "./pay.svg";

export const images = {
  pay,
  copy,
  arrowDown,
  arrowUp,
  dashboard,
  navBarArrowDown,
  navBarArrowUp,
  defaulters,
  threeDots,
  rider,
  nownow,
  officeSpace,
  search,
  oodia,
  check,
  order,
  earnings,
  recentOrder,
  personLine,
  percentUp,
  closeBtn,
  trackMap,
  location,
  line77,
  pickup,
  dropOff,
  line167,
  profilePix,
  motocycle,
  filetype,
  fileUpload,
  upload,
};
