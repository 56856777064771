import { clsx } from "clsx";
import { twMerge } from "tailwind-merge";
import { store } from "../store";
import toast from "react-hot-toast";
import axios from "axios";

export function cn(...inputs) {
  return twMerge(clsx(inputs));
}

export const parseMoney = (amount) => {
  const newAmount = parseFloat(amount);
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "NGN",
    currencyDisplay: "symbol",
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
  });
  const formattedAmount = formatter.format(newAmount);
  return `₦${formattedAmount.substring(3)}`;
};

const refreshTokenCall = async () => {
  const { auth } = store.getState();
  try {
    const response = await axios.post(
      "https://api.nownowdispatch.com/auth/api/v1/auth/refresh",
      {
        refreshToken: auth.refreshToken,
      },
      {
        headers: {
          "Content-Type": "application/json",
          "client": "admin",
        },
      }
    );
    const { accessToken, refreshToken, expiresIn } = response.data.data;
    store.dispatch(setTokens({ accessToken, refreshToken, expiresIn }));

    return accessToken;
  } catch (error) {
    store.dispatch(clearTokens());
    toast.error("Unable to refresh token", { id: "token" });
  }
};

export const getAccessToken = async () => {
  try {
    const savedUserData = store.getState()?.auth;
    if (savedUserData?.data) {
      return savedUserData?.data?.accessToken;
    } else {
      return null;
    }
  } catch (error) {
    return null;
  }
};
export const saveToLocalStorage = (key, value) => {
  try {
    const serializedValue = JSON.stringify(value);
    localStorage.setItem(key, serializedValue);
  } catch (error) {
    console.error("Error saving to localStorage", error);
  }
};
export const getFromLocalStorage = (key) => {
  try {
    const serializedValue = localStorage.getItem(key);
    if (serializedValue === null) {
      return "";
    }
    return JSON.parse(serializedValue);
  } catch (error) {
    console.error("Error retrieving from localStorage", error);
    return "";
  }
};
export const writeClipboardText = async (message, text) => {
  try {
    await navigator.clipboard.writeText(text);
    toast.success(message, { id: "copy" });
  } catch (error) {
    console.error(error.message);
  }
};
export const rolesTransform = {
  "15": "Account",
  "17": "Customer Service",
  "16": "Logistics",
  "18": "Admin-Operations",
  "19": "Super Admin",
};
