import React, { useState } from "react";
import { cn } from "../../Lib/utils";
import clsx from "clsx";

export default function NavTabs({ className, tabs, activeTab, setActiveTab }) {
  return (
    <div className={clsx("border-b border-gray-200", className)}>
      <nav
        className='-mb-px justify-center w-full md:w-0 md:justify-normal flex space-x-2  md:space-x-8'
        aria-label='Tabs'
      >
        {tabs.map((tab) => (
          <button
            key={tab.id}
            onClick={() => setActiveTab(tab.id)}
            className={cn(
              "whitespace-nowrap py-2 px-1 border-b-4 rounded font-medium text-md",
              activeTab === tab.id
                ? "border-primaryGreen text-primaryBlack font-bold"
                : "border-transparent text-[#667499]  hover:text-[text-[#667499]/90 hover:border-[#667499]/90"
            )}
            aria-current={activeTab === tab.id ? "page" : undefined}
          >
            {tab.name}
          </button>
        ))}
      </nav>
    </div>
  );
}
