import React, { useEffect, useState } from "react";
import DataTable from "../../Components/ui/dataTable";
import AssignRider from "./AssignRider";
import { images } from "../../Assets";
import { Button } from "../../Components/ui/button";
import { parseMoney, writeClipboardText } from "../../Lib/utils";
import { Modal, ModalContent, ModalTrigger } from "../../Components/ui/modal";
import TrackOrder from "./TrackOrder";
import Refresh from "../../Assets/refresh";
import { useDispatch, useSelector } from "react-redux";
import MapComponent from "../../Components/Map";
import clsx from "clsx";
import {
  useCancelOrderMutation,
  useGetAllRidersRequestQuery,
  useGetDashboardOrdersQuery,
} from "./DashboardApi";
import { SkeletonTable } from "../../Components/ui/skeleton";
import Pagination from "../../Components/ui/pagination";
import { format } from "date-fns";
import ModalComponent from "../../Components/ui/ModalComponent";
import toast from "react-hot-toast";
import Spinner from "../../Assets/spinner";
import MapComponentBeta from "../../Components/MapComponentBeta";
import { useGetAllRidersQuery } from "../Riders/RiderApi";
import { setRiders, updateCurrentOrders } from "../../Lib/slices/locationSlice";

const Dashboard = ({ trackAlone }) => {
  const [open, setOpen] = useState(false);
  const [id, setId] = useState("");
  const dispatch = useDispatch();
  const columns = [
    {
      accessorKey: "customerName",
      header: "Customer Name",
      cell: ({ row }) => (
        <div className='capitalize'>{row.getValue("customerName")}</div>
      ),
    },
    {
      accessorKey: "item",
      header: "Item",
      cell: ({ row }) => (
        <div className='capitalize'>{row.getValue("item")}</div>
      ),
    },
    {
      accessorKey: "status",
      header: "Order Status",
      cell: ({ row }) => {
        if (row.getValue("status") === "pending") {
          return (
            <Button asChild className='w-full' variant='pending'>
              <p>Pending</p>
            </Button>
          );
        }
        if (row.getValue("status") === "delivered") {
          return (
            <Button asChild className='w-full' variant='successful'>
              <p>Delivered</p>
            </Button>
          );
        }
        if (row.getValue("status") === "in_progress") {
          return (
            <Button asChild className='w-full'>
              <p>In-Progress</p>
            </Button>
          );
        }
        if (row.getValue("status") === "picked_up") {
          return (
            <Button asChild className='w-full'>
              <p>In-Progress</p>
            </Button>
          );
        }
        if (row.getValue("status") === "cancelled") {
          return (
            <Button asChild className='w-full' variant='failed'>
              <p>Cancelled</p>
            </Button>
          );
        }
      },
    },
    {
      accessorKey: "requestStatus",
      header: "Rider Status",
      cell: ({ row }) => {
        if (row.getValue("requestStatus") === "pending") {
          return (
            <Button asChild className='w-full' variant='pending'>
              <p>Pending</p>
            </Button>
          );
        }
        if (row.getValue("requestStatus") === "declined") {
          return (
            <Button asChild className='w-full' variant='failed'>
              <p>Declined</p>
            </Button>
          );
        }
        if (row.getValue("requestStatus") === "expired") {
          return (
            <Button asChild className='w-full' variant='failed'>
              <p>Expired</p>
            </Button>
          );
        }
      },
    },
    {
      id: "track",
      cell: ({ row }) => {
        const { orderId, requestStatus } = row.original;
        return (
          <Modal>
            <ModalContent name='Track Order'>
              <TrackOrder id={orderId} requestStatus={requestStatus} />
            </ModalContent>
            <ModalTrigger>
              <Button
                variant='outline'
                className='outline-none focus:outline-none focus-visible:outline-none px-4'
              >
                Track
              </Button>
            </ModalTrigger>
          </Modal>
        );
      },
    },
    {
      id: "cancel",
      cell: ({ row }) => {
        const details = row.original?.orderId;
        return (
          <>
            <Button
              onClick={() => {
                setOpen(true);
                setId(details);
              }}
              variant='cancelled'
              className='outline-none focus:outline-none focus-visible:outline-none px-4'
            >
              Cancel Order
            </Button>
          </>
        );
      },
    },
  ];
  const [page, setPage] = useState(1);
  const {
    data,
    isLoading: loading,
    refetch,
    isFetching,
  } = useGetAllRidersRequestQuery(
    {
      page,
      startDate: format(new Date(), "yyy-MM-dd"),
      endDate: format(new Date(), "yyy-MM-dd"),
    },
    {
      refectchOnFocus: true,
      refetchOnReconnect: true,
      refetchOnMountOrArgChange: true,
      // pollingInterval: 10000,
      // skipPollingIfUnfocused: true,
    }
  );
  const { data: allRiderData } = useGetAllRidersQuery();
  const [cancelOrder, { isLoading }] = useCancelOrderMutation();
  const [btn, setBtn] = useState(1);
  const [newData, setNewData] = useState([]);
  const handleCancelOrder = async (id) => {
    try {
      const data = await cancelOrder(id).unwrap();
      if (data.statusCode === 200) {
        setOpen(false);
        toast.success("Order Cancelled", { id: "orderCan" });
      }
    } catch (error) {
      toast.error("Please try again", { id: "order err" });
    }
  };
  useEffect(() => {
    try {
      if (loading === false && isFetching === false) {
        dispatch(updateCurrentOrders(data?.data));
        if (btn === 1) {
          const activatedUsers = data?.data?.filter(
            (user) => user.status === "pending"
          );
          setNewData(activatedUsers);
        } else if (btn === 2) {
          const activatedUsers = data?.data?.filter(
            (user) =>
              user.status === "in_progress" || user.status === "picked_up"
          );
          setNewData(activatedUsers);
        } else if (btn === 3) {
          const activatedUsers = data?.data?.filter(
            (user) => user.status === "delivered"
          );
          setNewData(activatedUsers);
        } else {
          const activatedUsers = data?.data?.filter(
            (user) => user.status === "cancelled"
          );
          setNewData(activatedUsers);
        }
      }
    } catch (error) {}
  }, [loading, isFetching, btn]);

  return (
    <>
      <div className='mb-20 bg-white'>
        {trackAlone !== 16 && (
          <div className='flex w-full mb-5 flex-col md:grid gap-4 md:grid-cols-2 lg:gap-6'>
            <AssignRider />
            <MapComponentBeta />
          </div>
        )}
        <div className='bg-white p-2'>
          <div className='flex flex-col md:flex-row items-center justify-between my-2'>
            <p className='font-bold text-primaryBlack text-md'>
              Order Table - {data?.data.length ?? null}
            </p>

            <div className='flex items-center gap-2 font-semibold bg-[#F5FEF1] w-auto p-2 rounded-sm'>
              <button
                onClick={() => setBtn(1)}
                className={clsx(
                  "text-primaryBlack rounded-sm text text-sm p-2 hover:bg-[#FEF6CF]/80",
                  btn === 1 && "bg-[#FEF6CF] text-[#6B2B0D]"
                )}
              >
                Pending
              </button>
              <button
                onClick={() => setBtn(2)}
                className={clsx(
                  "text-primaryBlack rounded-sm text text-sm p-2 font-semibold hover:bg-primaryGreen/80 flex items-center gap-1",
                  btn === 2 && "bg-primaryGreen text-white"
                )}
              >
                In-Progress
                {btn === 2 && (
                  <span class='relative flex h-3 w-3'>
                    <span class='animate-ping absolute inline-flex h-full w-full rounded-full bg-white opacity-75'></span>
                    <span class='relative inline-flex rounded-full h-3 w-3 bg-white'></span>
                  </span>
                )}
              </button>
              <button
                onClick={() => setBtn(3)}
                className={clsx(
                  "text-primaryBlack rounded-sm text font-semibold text-sm p-2 hover:bg-[#BEF2B9]/80",
                  btn === 3 && "bg-[#BEF2B9] text-[#163C29]"
                )}
              >
                Delivered
              </button>
              <button
                onClick={() => setBtn(4)}
                className={clsx(
                  "text-primaryBlack rounded-sm text font-semibold text-sm p-2 hover:bg-[#F4B7B5]/80",
                  btn === 4 && "bg-[#F4B7B5] text-[#590C07]"
                )}
              >
                Cancelled
              </button>
            </div>
            <Button variant='outline' onClick={refetch}>
              <Refresh buttonSpin={isFetching} />
            </Button>
          </div>
        </div>
        <div className='max-h-[70vh] overflow-auto  max-w-full rounded-md border'>
          <div className='w-full'>
            {loading ? (
              <SkeletonTable />
            ) : (
              <DataTable
                columns={columns}
                data={newData}
                columnVisibility={{
                  rider: btn !== 1,
                  cancel: btn !== 3 && btn !== 4,
                  track: btn !== 3 && btn !== 4,
                  requestStatus: btn === 1,
                }}
              />
            )}
          </div>
        </div>
        {!loading && (
          <Pagination
            page={page}
            limit={data?.limit ?? 0}
            total={data?.total ?? 0}
            setPage={setPage}
          />
        )}
      </div>
      <ModalComponent open={open} setOpen={setOpen} title={"Cancel?"}>
        <div>
          <p className='text-center mb-4'>
            Are you sure you want to cancel this order?
          </p>
          <div className='flex justify-between items-center bg-white w-[100%] '>
            <Button
              variant='successful'
              className='w-[30%]'
              onClick={() => handleCancelOrder(id)}
            >
              {isLoading ? <Spinner /> : "Yes"}
            </Button>
            <Button
              variant='failed'
              className='w-[30%]'
              onClick={() => setOpen(false)}
            >
              No
            </Button>
          </div>
        </div>
      </ModalComponent>
    </>
  );
};

export default Dashboard;
