import baseApi from "../../../Lib/api/baseApi";
import endpoints from "../../../Lib/api/endpoints";

export const AccountApi = baseApi.injectEndpoints({
  tagTypes: ["Account"],
  endpoints: (builder) => ({
    getAccountMetrics: builder.query({
      query: (data) => ({
        ...endpoints.getAccountMetrics(data),
      }),
      transformResponse: (response) => {
        return response;
      },
    }),
    getWallet: builder.query({
      query: () => ({
        ...endpoints.getWallet,
      }),
      transformResponse: (response) => {
        return response;
      },
    }),
    getPeriodicEarnings: builder.query({
      query: (data) => ({
        ...endpoints.getPeriodicTotalEarnings(data),
      }),
      transformResponse: (response) => {
        return response;
      },
      providesTags: (result) =>
        result
          ? [{ type: "Accounts", id: "LIST" }]
          : [{ type: "Accounts", id: "LIST" }],
    }),
    postInitiatePayout: builder.mutation({
      query: (data) => ({
        ...endpoints.completePayment,
        data,
      }),
      invalidatesTags: [{ type: "Accounts", id: "LIST" }],
    }),
  }),
});

export const {
  useGetAccountMetricsQuery,
  useGetPeriodicEarningsQuery,
  usePostInitiatePayoutMutation,
  useGetWalletQuery,
} = AccountApi;
