import React, { useState } from "react";
import { Button } from "./ui/button";
import Search from "./Search";
import { DatePickerWithRange } from "./ui/dateRange";
import NavTabs from "./ui/tabs";
import Dashboard from "../Pages/Dashboard";
import TrackOrder from "../Pages/Dashboard/TrackOrder";

import {
  Modal,
  ModalTrigger,
  ModalPortal,
  ModalOverlay,
  ModalClose,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalTitle,
  ModalDescription,
} from "../Components/ui/modal";

export function DialogDemo({ children }) {
  return (
    <Modal>
      <ModalTrigger asChild>{children}</ModalTrigger>
      <ModalContent name='Test Modal'>
        <Dashboard />
      </ModalContent>
    </Modal>
  );
}

const Test = () => {
  return (
    <div className='bg-white'>
      <p>Test page</p>
      <Button variant='successful'>Delivered</Button>
      <DialogDemo>
        <Button> Default</Button>
      </DialogDemo>
      <Button variant='ghost'>Ghost</Button>
      <Button variant='failed'>Failed</Button>
      <Button variant='cancelled'> cancelled</Button>
      <Button variant='pending'>Pending</Button>
      <Button variant='outline'>Outline</Button>
      <Search />

      <DatePickerWithRange />
      {/* <NavTabs /> */}
      {/* <ActiveDashboard /> */}
      {/* <TrackOrder /> */}
    </div>
  );
};

export default Test;
