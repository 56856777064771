import { useState } from "react";

import { Navbar, Sidebar } from "./index";
import { Outlet } from "react-router-dom";

export default function Layout() {
  return (
    <div className='flex '>
      <Sidebar />
      <main className=' px-2 md:px-6 mt-100px w-full flex flex-col md:flex-row'>
        <div className='w-full md:w-72'>
          <Navbar />
        </div>
        <div className='w-full flex-1 md:w-[75%] mx-auto mt-[90px]'>
          <Outlet />
        </div>
      </main>
    </div>
  );
}
