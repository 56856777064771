import React, { useState } from "react";
import DataTable from "../../Components/ui/dataTable";
import { DatePickerWithRange } from "../../Components/ui/dateRange";
import { images } from "../../Assets";
import { format, parseISO } from "date-fns";
import { Button } from "../../Components/ui/button";
import { parseMoney } from "../../Lib/utils";
import { SkeletonTable } from "../../Components/ui/skeleton";
import Pagination from "../../Components/ui/pagination";
import { useRiderHistoryQuery } from "./RiderApi";

const RiderRides = ({ id }) => {
  const [page, setPage] = useState(1);
  const { data, isLoading: loading } = useRiderHistoryQuery(id);
  const columns = [
    {
      accessorKey: "item",
      header: "Item",
      cell: ({ row }) => (
        <div className='font-semibold'>{row.original?.order?.item}</div>
      ),
    },
    {
      accessorKey: "riderFee",
      header: "Rider Fee",
      cell: ({ row }) => (
        <div className='font-semibold'>
          {parseMoney(row.original?.riderFee)}
        </div>
      ),
    },
    {
      accessorKey: "dateAndTime",
      header: "Date & Time ",
      cell: ({ row }) => (
        <div className='font-semibold'>
          {format(parseISO(row.original?.createdAt), "LLL dd, y hh:mm a")}
        </div>
      ),
    },
    {
      accessorKey: "status",
      header: "Status",
      cell: ({ row }) => {
        if (row?.original?.order?.currentStatus === "pending") {
          return (
            <Button asChild className='w-[100px]' variant='pending'>
              <p>Pending</p>
            </Button>
          );
        }
        if (row?.original?.order?.currentStatus === "delivered") {
          return (
            <Button asChild className='w-[100px]' variant='delivered'>
              <p>Delivered</p>
            </Button>
          );
        }
        if (row?.original?.order?.currentStatus === "in_progress") {
          return (
            <Button asChild className='w-[100px]' variant='outline'>
              <p>In-Progress</p>
            </Button>
          );
        }
        if (row?.original?.order?.currentStatus === "failed") {
          return (
            <Button asChild className='w-[100px]' variant='failed'>
              <p>Rejected</p>
            </Button>
          );
        }
        if (row?.original?.order?.currentStatus === "cancelled") {
          return (
            <Button asChild className='w-[100px]' variant='cancelled'>
              <p>Cancelled</p>
            </Button>
          );
        }
      },
    },
  ];
  return (
    <div className='w-full mt-5 bg-white rounded-md p-2 shadow-md'>
      <div className='mb-2'>
        <h3 className='text-lg font-medium p-2 m-2 text-left mb-2 text-primaryBlack'>
          Rides
        </h3>
      </div>
      <div className='border-b-2 border-gray-100 mb-5'></div>
      <div className='mt-5'>
        <div className='max-h-[50vh] overflow-auto  max-w-full rounded-md border'>
          <div className='w-full'>
            {loading ? (
              <SkeletonTable />
            ) : (
              <DataTable columns={columns} data={data?.data?.data} />
            )}
          </div>
        </div>
        {!loading && (
          <Pagination
            page={page}
            limit={data?.data?.limit ?? 0}
            total={data?.data?.total ?? 0}
            setPage={setPage}
          />
        )}
      </div>
    </div>
  );
};

export default RiderRides;
