import React from "react";

const DashboardIcon = ({ color = "#BDBDBD" }) => {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M9 2H4C2.89543 2 2 2.89543 2 4V11C2 12.1046 2.89543 13 4 13H9C10.1046 13 11 12.1046 11 11V4C11 2.89543 10.1046 2 9 2Z'
        fill={color}
      />
      <path
        d='M20 2H15C13.8954 2 13 2.89543 13 4V7C13 8.10457 13.8954 9 15 9H20C21.1046 9 22 8.10457 22 7V4C22 2.89543 21.1046 2 20 2Z'
        fill={color}
      />
      <path
        d='M9 15H4C2.89543 15 2 15.8954 2 17V20C2 21.1046 2.89543 22 4 22H9C10.1046 22 11 21.1046 11 20V17C11 15.8954 10.1046 15 9 15Z'
        fill={color}
      />
      <path
        d='M20 11H15C13.8954 11 13 11.8954 13 13V20C13 21.1046 13.8954 22 15 22H20C21.1046 22 22 21.1046 22 20V13C22 11.8954 21.1046 11 20 11Z'
        fill={color}
      />
    </svg>
  );
};

export default DashboardIcon;
