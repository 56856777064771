import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  deliveryLocation: { lat: null, lng: null },
  pickUpLocation: { lat: null, lng: null },
  driverLocation: { lat: 4.9774587, lng: 8.341548399999999 },
  availableRiders: [],
  riderLocation: [],
  riders: {},
  currentOrders: [],
};

export const locationSlice = createSlice({
  name: "location",
  initialState,
  reducers: {
    updateDeliveryLocation: (state, action) => {
      state.deliveryLocation = action.payload;
    },
    updateCurrentOrders: (state, action) => {
      state.currentOrders = action.payload;
    },
    updatePickUpLocation: (state, action) => {
      state.pickUpLocation = action.payload;
    },
    updateDriverLocation: (state, action) => {
      state.driverLocation = action.payload;
    },
    updateRidersLocation: (state, action) => {
      state.riderLocation = action.payload;
    },
    resetLocation: (state, action) => {
      state.deliveryLocation = { lat: null, lng: null };
      state.pickUpLocation = { lat: null, lng: null };
      state.driverLocation = { lat: 4.9774587, lng: 8.341548399999999 };
    },
    setRiders: (state, action) => {
      state.riders = action.payload;
    },
    updateAvailableRiders: (state, action) => {
      state.availableRiders = action.payload;
    },
  },
});

export const {
  updateDeliveryLocation,
  updateDriverLocation,
  updatePickUpLocation,
  resetLocation,
  updateAvailableRiders,
  setRiders,
  updateCurrentOrders,
  updateRidersLocation,
} = locationSlice.actions;
export default locationSlice.reducer;
