import React, { useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { images } from "../Assets";
import DashboardIcon from "../Assets/dashboardIcon";
import OrderIcon from "../Assets/orderIcon";
import clsx from "clsx";
import RidersIcon from "../Assets/ridersIcon";
import AccountIcon from "../Assets/accountIcon";
import UserIcon from "../Assets/userIcon";
import SettingsIcon from "../Assets/settingsIcon";
import SignoutIcon from "../Assets/signoutIcon";
import { useScreenWidth } from "../Lib/hooks";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../Lib/slices/authSlice";

export default function Sidebar() {
  const small = useScreenWidth("max-width('768px')");
  const location = useLocation();
  const dispatch = useDispatch();
  const pathname = location.pathname.split("/").filter((segment) => segment)[0];
  const { roleId } = useSelector((state) => state.auth.data);
  return (
    small && (
      <div className='hidden h-full md:flex md:fixed md:inset-y-0 w-72 pt-3 bg-white z-20'>
        <nav className='flex flex-col items-start w-[70%] mx-auto gap-4 pb-10'>
          <img
            src={images.nownow}
            className='w-[80%] h-auto mb-4'
            alt='nownow rider'
          />
          {(roleId === 17 ||
            roleId === 3 ||
            roleId === 16 ||
            roleId === 19) && (
            <NavLink
              to='dashboard'
              className={clsx(
                pathname === "dashboard" && "bg-primaryGreen",
                "flex items-center gap-2 w-full p-3 h-12 rounded-md hover:border border-primaryGreen"
              )}
            >
              <DashboardIcon
                color={pathname === "dashboard" ? "#fff" : undefined}
              />
              <p
                className={clsx(
                  pathname === "dashboard" ? "text-white" : "text-primaryAsh",
                  "text-md font-medium"
                )}
              >
                Dashboard
              </p>
            </NavLink>
          )}
          {(roleId === 15 || roleId === 3 || roleId === 19) && (
            <NavLink
              to='orders'
              className={clsx(
                pathname === "orders" && "bg-primaryGreen",
                "flex items-center gap-2 w-full p-3 h-12 rounded-md hover:border border-primaryGreen"
              )}
            >
              <OrderIcon color={pathname === "orders" ? "#fff" : undefined} />
              <p
                className={clsx(
                  pathname === "orders" ? "text-white" : "text-primaryAsh",
                  "text-md font-medium"
                )}
              >
                Orders
              </p>
            </NavLink>
          )}
          {(roleId === 16 ||
            roleId === 18 ||
            roleId === 3 ||
            roleId === 19) && (
            <NavLink
              to='riders'
              className={clsx(
                pathname === "riders" && "bg-primaryGreen",
                "flex items-center gap-2 w-full p-3 h-12 rounded-md hover:border border-primaryGreen"
              )}
            >
              <RidersIcon color={pathname === "riders" ? "#fff" : undefined} />
              <p
                className={clsx(
                  pathname === "riders" ? "text-white" : "text-primaryAsh",
                  "text-md font-medium"
                )}
              >
                Riders
              </p>
            </NavLink>
          )}
          {(roleId === 15 || roleId === 3 || roleId === 19) && (
            <NavLink
              to='accounts'
              className={clsx(
                pathname === "accounts" && "bg-primaryGreen",
                "flex items-center gap-2 w-full p-3 h-12 rounded-md hover:border border-primaryGreen"
              )}
            >
              <AccountIcon
                color={pathname === "accounts" ? "#fff" : undefined}
              />
              <p
                className={clsx(
                  pathname === "accounts" ? "text-white" : "text-primaryAsh",
                  "text-md font-medium"
                )}
              >
                Accounts
              </p>
            </NavLink>
          )}
          {(roleId === 3 || roleId === 19) && (
            <NavLink
              to='users'
              className={clsx(
                pathname === "users" && "bg-primaryGreen",
                "flex items-center gap-2 w-full p-3 h-12 rounded-md hover:border border-primaryGreen"
              )}
            >
              <UserIcon color={pathname === "users" ? "#fff" : undefined} />
              <p
                className={clsx(
                  pathname === "users" ? "text-white" : "text-primaryAsh",
                  "text-md font-medium"
                )}
              >
                Users
              </p>
            </NavLink>
          )}
          {(roleId === 3 || roleId === 19) && (
            <NavLink
              to='audit'
              className={clsx(
                pathname === "audit" && "bg-primaryGreen",
                "flex items-center gap-2 w-full p-3 h-12 rounded-md hover:border border-primaryGreen "
              )}
            >
              <SettingsIcon color={pathname === "audit" ? "#fff" : undefined} />
              <p
                className={clsx(
                  pathname === "audit" ? "text-white" : "text-primaryAsh",
                  "text-md font-medium"
                )}
              >
                Audit Logs
              </p>
            </NavLink>
          )}
          <button
            className='flex items-center gap-2 w-full p-3 h-12 rounded-md border border-primaryGreen mt-auto text-primaryAsh hover:bg-primaryGreen hover:text-white'
            onClick={() => dispatch(logout())}
          >
            <SignoutIcon />
            <p className={"text-md font-medium"}>Logout</p>
          </button>
        </nav>
      </div>
    )
  );
}
