import React from "react";
import { Button } from "./ui/button";
import { ArrowLeftIcon } from "@radix-ui/react-icons";
import { useNavigate } from "react-router-dom";

const Goback = ({ currentRoute }) => {
  const navigate = useNavigate();
  return (
    <div className='flex items-center gap-4 my-4'>
      <Button variant='outline' onClick={() => navigate(-1)}>
        <ArrowLeftIcon className='h-5 w-5 mr-1 text-primaryGreen' />
        Go back
      </Button>
      <p className='text-primaryGreen font-bold text-lg'>{currentRoute}</p>
    </div>
  );
};

export default Goback;
