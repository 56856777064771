import React from "react";

const Stepper = ({ steps }) => {
  return (
    <div className='container mx-auto md:p-4'>
      <div className='flex relative md:flex-row'>
        {steps.map((step, index) => (
          <div key={index} className='flex-1 text-center relative'>
            <div className='flex items-center justify-center'>
              <div
                className={`w-8 h-8 rounded-full flex items-center justify-center border-2 ${
                  step.time
                    ? "bg-primaryGreen text-white border-primaryGreen"
                    : "bg-white border-gray-300"
                }`}
              >
                {step.time ? (
                  <span>&#10003;</span> // Checkmark symbol
                ) : (
                  index + 1
                )}
              </div>
            </div>
            {index !== steps.length - 1 && (
              <div
                className={`absolute top-4 left-[calc(50%+16px)] w-full h-1 ${
                  steps[index + 1].time ? "bg-primaryGreen" : "bg-gray-300"
                }`}
              ></div>
            )}
            <div className='mt-2 flex flex-col'>
              <h4 className='text-xs md:text-sm font-medium capitalize'>
                {step.label}
              </h4>
              <p className='text-xs text-gray-500'>{step.time}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Stepper;
