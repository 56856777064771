import axios from "axios";
import { getAccessToken } from "../utils";
import toast from "react-hot-toast";
import { store } from "../../store";
import { logout } from "../slices/authSlice";

const axiosBaseQuery = ({ baseUrl } = { baseUrl: "" }) => {
  return async ({ url, method, data, params, headers, options }) => {
    try {
      const setHeaders = async () => {
        const accessToken = await getAccessToken();

        if (accessToken) {
          return {
            ...headers,
            Authorization: `Bearer ${accessToken}`,
            client: "admin",
          };
        } else {
          return {
            ...headers,
            client: "admin",
          };
        }
      };

      const result = await axios({
        url,
        baseURL: baseUrl,
        method,
        data,
        params,
        headers: await setHeaders(),
        timeout: 1000 * 60 * 3,
      });

      return {
        data: result?.data,
      };
    } catch (axiosError) {
      const err = axiosError;
      if (
        axiosError?.response?.data?.statusCode === 401 ||
        axiosError?.response?.data?.statusCode === 403
      ) {
        store.dispatch(logout());
      }
      toast.error(axiosError?.response?.data?.message || axiosError?.message, {
        id: "axiosError",
      });
      return {
        error: {
          status: err?.response?.status,
          data: err?.response?.data || err?.message,
        },
      };
    } finally {
    }
  };
};

export default axiosBaseQuery;
