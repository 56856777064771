import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { images } from "../../Assets";
import { ErrorMessage, FormikProvider, useFormik } from "formik";
import * as Yup from "yup";
import { ArrowLeftIcon } from "@radix-ui/react-icons";
import { useForgotPasswordMutation } from "./AuthApi";
import Spinner from "../../Assets/spinner";
import toast from "react-hot-toast";

const ForgotPassword = () => {
  const navigate = useNavigate();
  const [postPassword] = useForgotPasswordMutation();
  const [submitLoading, setSubmitLoading] = useState(false);
  const initialValues = {
    email: "",
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string().email("Invalid email").required("Email is required"),
  });

  const handleForgotPw = async (values) => {
    try {
      setSubmitLoading(true);
      const { data } = await postPassword({ email: values?.email });
      if (data.statusCode === 200) {
        setSubmitLoading(false);
        toast.success(data?.message, { id: "fpw" });
        navigate("/otp", { state: data?.data?.email });
      }
    } catch (error) {
      setSubmitLoading(false);
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      await handleForgotPw(values);
    },
  });

  const { handleSubmit, handleChange, handleBlur, values, errors, touched } =
    formik;

  return (
    <FormikProvider value={formik}>
      <form onSubmit={handleSubmit}>
        <div className='h-[100vh]'>
          <div className='md:flex items-center h-full justify-between'>
            <div className='w-[45%] hidden md:block mb-2 p-10'>
              <img
                src={images.officeSpace}
                className='w-full h-auto rounded-lg'
                alt='nownow rider'
              />
            </div>
            <div className='w-full md:w-[50%] px-10 text-center mb-10 mt-0'>
              <div className='mb-10 flex justify-center'>
                <img
                  src={images.nownow}
                  className='w-[40%] h-auto'
                  alt='nownow rider'
                />
              </div>
              <p className='font-bold text-xl mt-10'>Forgot Password?</p>
              <p className='font-medium text-md text-center mt-5 md:text-center'>
                Don’t worry! It happens. Please enter the email address
                associated with your account to reset your password.
              </p>
              <div className='mt-10'>
                <div className='flex flex-col text-left w-[80%] mx-auto mt-10'>
                  <label htmlFor='email' className='font-medium mb-1 text-xs'>
                    Email
                  </label>
                  <input
                    id='email'
                    type='text'
                    className='bg-transparent border border-[#C2C7D6] py-2 px-3 rounded-md outline-none w-full'
                    value={values.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {errors.email && touched.email && (
                    <p className='text-xs text-red'>
                      <ErrorMessage name='email' />
                    </p>
                  )}
                </div>
                <div className='mt-10'>
                  <button
                    type='submit'
                    className='bg-primaryGreen flex justify-center mx-auto outline-none text-white w-[50%] py-2 rounded-md'
                    disabled={submitLoading}
                  >
                    {submitLoading ? <Spinner /> : "Continue"}
                  </button>
                </div>

                <div className='flex items-center justify-center mt-4'>
                  <Link to='/login' className='text-black flex items-center'>
                    <ArrowLeftIcon className='h-5 w-5 mr-1 text-primaryGreen' />
                    Back to Sign In
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </FormikProvider>
  );
};

export default ForgotPassword;
