const Refresh = ({ buttonSpin }) => {
  return (
    <svg
      width='16'
      height='16'
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={buttonSpin ? "animate-spin" : null}
    >
      <path
        d='M0.925781 0H3.05001V2.71592C4.31394 1.72275 5.90773 1.13041 7.63987 1.13041C11.746 1.13041 15.0747 4.45908 15.0747 8.5652C15.0747 12.6713 11.746 16 7.63987 16C5.35581 16 3.31231 14.97 1.94848 13.3492L3.66839 12.0908C4.64118 13.1858 6.05995 13.8758 7.63987 13.8758C10.5728 13.8758 12.9504 11.4981 12.9504 8.5652C12.9504 5.63226 10.5728 3.25464 7.63987 3.25464C6.48552 3.25464 5.41719 3.62294 4.54599 4.24845L7.29846 4.24845V6.37268H0.925781V0Z'
        fill='#91B156'
      />
    </svg>
  );
};
export default Refresh;
