"use client";

import React, { useState, useId } from "react";
import { Check, ChevronsUpDown } from "lucide-react";
import { cn } from "../../Lib/utils";
import { Button } from "./button";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "./command";
import { Popover, PopoverContent, PopoverTrigger } from "./popover";

export function Combobox({
  search,
  frameworks,
  setValue,
  value,
  placeholder,
  isLoading,
}) {
  const id = useId();
  const [open, setOpen] = useState(false);
  //   const [value, setValue] = useState("");

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Button
          variant='default'
          role='combobox'
          aria-expanded={open}
          disabled={isLoading}
          className='w-full bg-transparent border text-primaryBlack border-[#C2C7D6] justify-between hover:bg-transparent focus:outline-none'
        >
          {value
            ? frameworks.find((framework) => framework.value === value)?.label
            : `Select ${placeholder}`}

          <ChevronsUpDown className='ml-2 h-4 w-4 shrink-0 opacity-50' />
        </Button>
      </PopoverTrigger>
      <PopoverContent className='w-[300px] p-2 bg-white rounded-md'>
        <Command>
          {search && <CommandInput placeholder='Search framework...' />}
          <CommandEmpty>Empty.</CommandEmpty>
          <CommandGroup>
            {frameworks.map((framework, frameid) => (
              <CommandList key={frameid}>
                <CommandItem
                  key={id}
                  //   value={framework.value}
                  onSelect={() => {
                    setValue(framework);
                    setOpen(false);
                  }}
                  className='hover:bg-primaryGreen hover:text-white group rounded-sm'
                >
                  <Check
                    className={cn(
                      "mr-2 h-4 w-4 text-primaryBlack group-hover:text-white",
                      value === framework.value ? "opacity-100" : "opacity-0"
                    )}
                  />
                  {framework.label}
                  {framework?.active && (
                    <p className='w-fit text-primaryGreen border-[1px] border-primaryGreen text-xs p-[1px] ml-auto rounded-md'>
                      Active
                    </p>
                  )}
                </CommandItem>
              </CommandList>
            ))}
          </CommandGroup>
        </Command>
      </PopoverContent>
    </Popover>
  );
}
