import baseApi from "../../../Lib/api/baseApi";
import endpoints from "../../../Lib/api/endpoints";

export const UserApi = baseApi.injectEndpoints({
  tagTypes: ["Data"],
  endpoints: (builder) => ({
    createNewAdmin: builder.mutation({
      query: (details) => ({
        ...endpoints.createNewAdmin,
        data: details,
      }),
      invalidatesTags: [{ type: "Data", id: "LIST" }],
    }),
    getAllAdmin: builder.query({
      query: () => ({
        ...endpoints.getAllAdmin,
      }),
      transformResponse: (response) => {
        return response?.data?.data?.map((d) => ({
          name: `${d.firstName} ${d.lastName}`,
          email: d.email,
          phoneNumber: d.phoneNumber,
          role: d.roleId,
          dateAdded: d.createdAt,
          activated: d.activated,
          emailVerified: d.emailVerified,
          id: d.id,
        }));
      },
      providesTags: (result) =>
        result
          ? [{ type: "Data", id: "LIST" }]
          : [{ type: "Data", id: "LIST" }],
    }),
    activateAdmin: builder.mutation({
      query: (userId) => ({
        ...endpoints.activateAdmin(userId),
      }),
      invalidatesTags: [{ type: "Data", id: "LIST" }],
    }),
    deactivateAdmin: builder.mutation({
      query: (userId) => ({
        ...endpoints.deactivateAdmin(userId),
      }),
      invalidatesTags: [{ type: "Data", id: "LIST" }],
    }),
    deleteAdmin: builder.mutation({
      query: (userId) => ({
        ...endpoints.deleteAdmin(userId),
      }),
      invalidatesTags: [{ type: "Data", id: "LIST" }],
    }),
    updateAdminRole: builder.mutation({
      query: (details) => ({
        ...endpoints.updateRole(details?.userId),
        data: { roleId: details?.roleId },
      }),
      invalidatesTags: [{ type: "Data", id: "LIST" }],
    }),
    getAllRoles: builder.query({
      query: () => ({
        ...endpoints.getAllRoles,
      }),
      transformResponse: (response) => {
        return response?.data
          ?.filter((d) => d.enabled === true)
          .map((d) => ({
            label: d.name,
            value: d.id,
          }));
      },
    }),
  }),
});

export const {
  useCreateNewAdminMutation,
  useGetAllAdminQuery,
  useActivateAdminMutation,
  useDeactivateAdminMutation,
  useDeleteAdminMutation,
  useUpdateAdminRoleMutation,
  useGetAllRolesQuery,
} = UserApi;
