import React from "react";
import { useLoadScript } from "@react-google-maps/api";
import { SkeletonTable } from "./ui/skeleton";

const WithGoogleMapsLoader = (WrappedComponent) => {
  return (props) => {
    const [libraries] = React.useState(["places"]);

    const { isLoaded, loadError } = useLoadScript({
      googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
      libraries,
    });

    if (loadError) {
      return <div>Error loading Google Maps</div>;
    }

    return !isLoaded ? <SkeletonTable /> : <WrappedComponent {...props} />;
  };
};

export default WithGoogleMapsLoader;
