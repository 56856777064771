import React, { useCallback, useEffect, useState } from "react";
import { images } from "../../Assets";
import { Link, useNavigate, useParams } from "react-router-dom";
import { ErrorMessage, FormikProvider, useFormik } from "formik";
import { SignUpValidationSchema } from "./AuthValidationSchema";
import { EyeOpenIcon, EyeNoneIcon } from "@radix-ui/react-icons";
import { Combobox } from "../../Components/ui/combobox";
import { useSignupMutation, useValidateTokenMutation } from "./AuthApi";
import toast from "react-hot-toast";
import Spinner from "../../Assets/spinner";
import { saveToLocalStorage } from "../../Lib/utils";

const SignUp = () => {
  const { token } = useParams();
  const navigate = useNavigate();
  const [signup] = useSignupMutation();
  const [validateToken] = useValidateTokenMutation();

  const [showPassword, setShowPassword] = useState(true);
  const [showConfirmPassword, setShowConfirmPassword] = useState(true);
  const [loadingtoken, setLoadingtoken] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);

  const initialValues = {
    firstName: "",
    lastName: "",
    phoneNumber: "",
    gender: "",
    password: "",
    confirmPassword: "",
    email: "",
  };

  const handleTokenValidation = useCallback(async () => {
    try {
      const { data } = await validateToken({ token });
      if (data.statusCode === 200) {
        setFieldValue("email", data?.data?.email);
        setLoadingtoken(true);
      }
    } catch (error) {
      navigate("/login", { replace: true });
    }
  }, [validateToken]);

  const handleSignup = async (values) => {
    const dataValues = {
      firstName: values?.firstName,
      lastName: values?.lastName,
      phoneNumber: values?.phoneNumber,
      gender: values?.gender,
      password: values?.password,
      email: values?.email,
    };

    try {
      setSubmitLoading(true);
      const { data } = await signup(dataValues);
      if (data.statusCode === 200) {
        saveToLocalStorage("user", {
          email: data?.data?.email,
          name: data?.data?.firstName,
        });
        toast.success("Profile Created Successfuly", { id: "profile" });
        navigate("/login", { replace: true });
      }
    } catch (error) {
      console.error("Signup error", error);
    } finally {
      setSubmitLoading(false);
    }
  };

  useEffect(() => {
    if (token) {
      handleTokenValidation();
    }
  }, [handleTokenValidation, token]);

  const formik = useFormik({
    initialValues,
    validationSchema: SignUpValidationSchema,
    onSubmit: async (values) => {
      await handleSignup(values);
    },
  });
  const { handleSubmit, setFieldValue, handleChange, handleBlur, values } =
    formik;
  return (
    <FormikProvider value={formik}>
      <form onSubmit={handleSubmit}>
        <div className='h-[100vh]'>
          <div className='md:flex items-center h-full justify-between'>
            <div className='hidden md:block w-[45%] p-10 '>
              <img
                src={images.officeSpace}
                className='w-full h-auto rounded-lg'
                alt='nownow rider'
              />
            </div>
            <div className='w-full md:w-[50%] px-10 text-center'>
              <div className='mb-10 flex justify-center'>
                <img
                  src={images.nownow}
                  className='w-[40%] h-auto'
                  alt='nownow rider'
                />
              </div>
              <p className='font-bold text-xl'>
                Welcome to the Admin Dashboard
              </p>
              {!loadingtoken && (
                <p className='font-medium text-md my-5'>
                  Please wait while we confirm your invitation...
                </p>
              )}
              {loadingtoken && (
                <>
                  <p className='font-medium text-md my-5'>
                    Create an Account to continue
                  </p>
                  <div className='md:flex gap-5 justify-between mt-5'>
                    <div className='flex flex-col text-left w-full md:w-[50%]'>
                      <label
                        htmlFor='firstName'
                        className='font-medium mb-1 text-xs'
                      >
                        First Name
                      </label>
                      <input
                        id='firstName'
                        className='bg-transparent border border-[#C2C7D6] py-2 px-1 rounded-md outline-none'
                        value={values.firstName}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <p className='text-xs text-red'>
                        <ErrorMessage name='firstName' />
                      </p>
                    </div>
                    <div className='mt-5 md:mt-0 flex flex-col text-left w-full md:w-[50%]'>
                      <label
                        htmlFor='lastName'
                        className='font-medium mb-1 text-xs'
                      >
                        Last Name
                      </label>
                      <input
                        id='lastName'
                        className='bg-transparent border border-[#C2C7D6] py-2 px-1 rounded-md outline-none'
                        value={values.lastName}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <p className='text-xs text-red'>
                        <ErrorMessage name='lastName' />
                      </p>
                    </div>
                  </div>
                  <div className='md:flex gap-5 justify-between mt-5'>
                    <div className='flex flex-col text-left w-full md:w-[50%]'>
                      <label
                        htmlFor='phoneNumber'
                        className='font-medium mb-1 text-xs'
                      >
                        Phone Number
                      </label>
                      <input
                        id='phoneNumber'
                        className='bg-transparent border border-[#C2C7D6] py-2 px-1 rounded-md outline-none'
                        value={values.phoneNumber}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <p className='text-xs text-red'>
                        <ErrorMessage name='phoneNumber' />
                      </p>
                    </div>
                    <div className='mt-5 md:mt-0 flex flex-col text-left w-full md:w-[50%]'>
                      <label
                        htmlFor='email'
                        className='font-medium mb-1 text-xs'
                      >
                        Gender
                      </label>
                      <Combobox
                        frameworks={[
                          { label: "Male", value: "male" },
                          { label: "Female", value: "female" },
                        ]}
                        setValue={(val) => {
                          setFieldValue("gender", val?.value);
                        }}
                        value={values.gender}
                        placeholder='Gender'
                      />
                      <p className='text-xs text-red'>
                        <ErrorMessage name='gender' />
                      </p>
                    </div>
                  </div>
                  <div className='md:flex gap-5 justify-between mt-5'>
                    <div className='flex flex-col text-left w-full md:w-[50%]'>
                      <label
                        htmlFor='password'
                        className='font-medium mb-1 text-xs'
                      >
                        Password
                      </label>
                      <div className='flex items-center justify-between bg-transparent border border-[#C2C7D6] py-2 px-1 pr-2 rounded-md'>
                        <div className='w-[80%]'>
                          <input
                            id='password'
                            type={!showPassword ? "text" : "password"}
                            className='bg-transparent outline-none'
                            value={values.password}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                        </div>
                        {!showPassword ? (
                          <button
                            className='w-[8%] p-1'
                            onClick={() => setShowPassword(true)}
                            type='button'
                          >
                            <EyeNoneIcon className='h-4 w-4 cursor-pointer' />
                          </button>
                        ) : (
                          <button
                            className='w-[8%] p-1'
                            onClick={() => setShowPassword(false)}
                            type='button'
                          >
                            <EyeOpenIcon className='h-4 w-4 cursor-pointer' />
                          </button>
                        )}
                      </div>
                      <p className='text-xs text-red'>
                        <ErrorMessage name='password' />
                      </p>
                    </div>
                    <div className='mt-5 md:mt-0 flex flex-col text-left w-full md:w-[50%]'>
                      <label
                        htmlFor='confirmPassword'
                        className='font-medium mb-1 text-xs'
                      >
                        Confirm Password
                      </label>
                      <div className='flex items-center justify-between bg-transparent border border-[#C2C7D6] py-2 px-1 pr-2 rounded-md'>
                        <div className='w-[80%]'>
                          <input
                            id='confirmPassword'
                            type={!showConfirmPassword ? "text" : "password"}
                            className='bg-transparent outline-none'
                            value={values.confirmPassword}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                        </div>
                        {!showConfirmPassword ? (
                          <button
                            className='w-[8%] p-1'
                            onClick={() => setShowConfirmPassword(true)}
                            type='button'
                          >
                            <EyeNoneIcon className='h-4 w-4 cursor-pointer' />
                          </button>
                        ) : (
                          <button
                            className='w-[8%] p-1'
                            onClick={() => setShowConfirmPassword(false)}
                            type='button'
                          >
                            <EyeOpenIcon className='h-4 w-4 cursor-pointer' />
                          </button>
                        )}
                      </div>
                      <p className='text-xs text-red'>
                        <ErrorMessage name='confirmPassword' />
                      </p>
                    </div>
                  </div>
                  <div className='mt-10 mb-5 md:mb-0'>
                    <button
                      type='submit'
                      className='bg-primaryGreen flex justify-center mx-auto outline-none text-white w-[50%] py-2 rounded-md'
                      disabled={submitLoading}
                    >
                      {submitLoading ? <Spinner /> : "Sign up"}
                    </button>
                    <p className='my-5'>
                      Already have an account?
                      <Link
                        to='/login'
                        className='cursor-pointer text-primaryGreen ml-2'
                      >
                        Sign In
                      </Link>
                    </p>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </form>
    </FormikProvider>
  );
};

export default SignUp;
