import React, { useState } from "react";
import { images } from "../../Assets";
import { Combobox } from "../../Components/ui/combobox";
import { Button } from "../../Components/ui/button";
import { Modal, ModalClose } from "../../Components/ui/modal";
import { ErrorMessage, FormikProvider, useFormik } from "formik";
import * as yup from "yup";
import { useCreateNewAdminMutation, useGetAllRolesQuery } from "./UserApi";
import toast from "react-hot-toast";
import Spinner from "../../Assets/spinner";

const InviteUser = () => {
  const [inviteUser, { isLoading: inviteLoading }] =
    useCreateNewAdminMutation();
  const { data, isLoading } = useGetAllRolesQuery();
  const InviteUserValidationSchema = yup.object().shape({
    email: yup
      .string()
      .required("Please Enter an Email")
      .matches(
        /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
        "Invalid email format"
      ),
    roleId: yup.string().required("Please select a Role"),
  });
  const handleCreateAdmin = async (values) => {
    try {
      const data = await inviteUser(values).unwrap();
      if (data.statusCode === 201) {
        toast.success("Admin invite sent successfuly", { id: "adminInvite" });
      }
    } catch (error) {}
  };
  const initialValues = {
    roleId: "",
    email: "",
  };
  const formik = useFormik({
    initialValues,
    validationSchema: InviteUserValidationSchema,
    onSubmit: async (values) => {
      await handleCreateAdmin(values);
    },
  });

  const {
    handleSubmit,
    handleChange,
    setFieldValue,
    handleBlur,
    values,
    errors,
    touched,
  } = formik;
  return (
    <FormikProvider value={formik}>
      <form onSubmit={handleSubmit}>
        <div className='w-[80vw] md:w-[400px]'>
          <p className='font-semibold text-lg text-primaryBlack'>
            Personal Information
          </p>
          <div className='flex flex-col gap-1'>
            <label className='text-primaryBlack my-2 text-md'>Email</label>
            <input
              className='p-2 outline-none bg-transparent border border-[#C2C7D6] rounded-md placeholder:text-sm '
              placeholder='johndoe@nownowdispatch.com'
              id='email'
              value={values.email}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            {errors.email && touched.email && (
              <p className='text-xs text-red'>
                <ErrorMessage name='email' />
              </p>
            )}
          </div>
          <div className='my-5'>
            <img className='w-full' src={images.line77} alt='line77' />
          </div>
          <p className='font-semibold text-lg text-primaryBlack'>
            Assign Role to this User
          </p>
          <div className='mt-5'>
            <Combobox
              frameworks={data || []}
              setValue={(val) => {
                setFieldValue("roleId", val?.value);
              }}
              value={values.roleId}
              placeholder='Role'
              disabled={isLoading}
            />
            {errors.email && touched.email && (
              <p className='text-xs text-red'>
                <ErrorMessage name='roleId' />
              </p>
            )}
          </div>
          <div className='mt-10 mb-20 flex justify-between'>
            <ModalClose>
              <Button
                variant='outline'
                type='button'
                className='font-medium text-md'
              >
                Cancel
              </Button>
            </ModalClose>
            <Button
              type='submit'
              className=' font-medium text-md'
              disabled={inviteLoading}
            >
              {inviteLoading ? <Spinner /> : "Invite User"}
            </Button>
          </div>
        </div>
      </form>
    </FormikProvider>
  );
};

export default InviteUser;
