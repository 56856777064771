import React, { useState, useRef } from "react";
import { images } from "../../Assets";

const OTPSixInput = ({ onComplete }) => {
  const [otpValues, setOtpValues] = useState(["", "", "", "", "", ""]);
  const inputRefs = [
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
  ];

  const handleInputChange = (index, value) => {
    const newOtpValues = [...otpValues];
    newOtpValues[index] = value;
    setOtpValues(newOtpValues);

    const isValidOTP = newOtpValues.every((val) => /^\d*$/.test(val));

    // Update border color based on validity
    inputRefs.forEach((ref, idx) => {
      if (ref.current) {
        ref.current.classList.remove("border-red", "border-gray-300");
        ref.current.classList.add(
          isValidOTP ? "border-gray-300" : "border-red"
        );
      }
    });
    if (value !== "" && index < 5) {
      inputRefs[index + 1].current.focus();
    }
    const isComplete = isValidOTP && newOtpValues.every((val) => val !== "");
    if (isComplete) {
      const fullOTP = newOtpValues.join("");
      onComplete(fullOTP);
    }
  };

  const handleKeyDown = (index, event) => {
    if (event.key === "Backspace" && index > 0 && otpValues[index] === "") {
      inputRefs[index - 1].current.focus();
    }
  };

  const isValidOTP = otpValues.every((val) => /^\d*$/.test(val));

  return (
    <div className='flex justify-center items-center'>
      {otpValues.map((value, index) => (
        <input
          key={index}
          ref={inputRefs[index]}
          type='text'
          maxLength='1'
          value={value}
          onChange={(e) => handleInputChange(index, e.target.value)}
          onKeyDown={(e) => handleKeyDown(index, e)}
          className={`w-10 md:w-12 h-12 text-center mx-1 border outline-none ${
            /^\d*$/.test(value) ? "border-primaryGreen" : "border-red"
          } rounded`}
        />
      ))}
      {isValidOTP &&
        otpValues.every((val) => val !== "") && ( // Render check image if OTP is valid and all fields are filled
          <img src={images.check} className='h-5 w-5 ml-2' alt='check' />
        )}
    </div>
  );
};

export default OTPSixInput;
