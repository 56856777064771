import baseApi from "../../../Lib/api/baseApi";
import endpoints from "../../../Lib/api/endpoints";

export const RiderApi = baseApi.injectEndpoints({
  tagTypes: ["Rider"],
  endpoints: (builder) => ({
    getAllRiders: builder.query({
      query: () => ({
        ...endpoints.getAllRiders,
      }),
      transformResponse: (response) => {
        return response?.data?.data?.map((d, index) => ({
          no: index + 1,
          name: `${d?.firstName} ${d?.lastName}`,
          email: d?.email,
          phoneNumber: d?.phoneNumber,
          createdAt: d?.createdAt,
          id: d?.id,
          gender: d?.gender || "",
          status: d?.status,
          activated: d?.activated,
        }));
      },
      providesTags: (result) =>
        result
          ? [{ type: "Rider", id: "All" }]
          : [{ type: "Rider", id: "All" }],
    }),
    getIndividualRider: builder.query({
      query: (id) => ({
        ...endpoints.getIndividualRiders(id),
      }),
      providesTags: (result) =>
        result
          ? [{ type: "Rider", id: "Single" }]
          : [{ type: "Rider", id: "Single" }],
    }),
    approveRider: builder.mutation({
      query: (id) => ({
        ...endpoints.approveRider(id),
      }),
      invalidatesTags: [
        { type: "Rider", id: "Single" },
        { type: "Rider", id: "All" },
      ],
    }),
    rejectRider: builder.mutation({
      query: (data) => ({
        ...endpoints.declineRider(data?.id),
        data: data?.body,
      }),
      invalidatesTags: [
        { type: "Rider", id: "Single" },
        { type: "Rider", id: "All" },
      ],
    }),
    deactivateRider: builder.mutation({
      query: (id) => ({
        ...endpoints.deactivateRider(id),
      }),
      invalidatesTags: [
        { type: "Rider", id: "Single" },
        { type: "Rider", id: "All" },
      ],
    }),
    activateRider: builder.mutation({
      query: (id) => ({
        ...endpoints.activateRider(id),
      }),
      invalidatesTags: [
        { type: "Rider", id: "Single" },
        { type: "Rider", id: "All" },
      ],
    }),
    riderHistory: builder.query({
      query: (id) => ({
        ...endpoints.getRiderHistory(id),
      }),
    }),
  }),
});

export const {
  useGetAllRidersQuery,
  useLazyGetAllRidersQuery,
  useGetIndividualRiderQuery,
  useApproveRiderMutation,
  useRejectRiderMutation,
  useRiderHistoryQuery,
  useDeactivateRiderMutation,
  useActivateRiderMutation,
} = RiderApi;
