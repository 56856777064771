import React, { FC } from "react";

const Spinner = ({ height = 25, width = 25 }) => {
  return (
    <svg
      className='animate-spin'
      width={width}
      height={height}
      viewBox='0 0 27 27'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <circle cx='13.25' cy='13.5' r='12' stroke='#284314' stroke-width='3' />
      <path
        d='M13.25 1.5C14.8259 1.5 16.3863 1.81039 17.8422 2.41345C19.2981 3.0165 20.621 3.90042 21.7353 5.01472C22.8496 6.12902 23.7335 7.45189 24.3366 8.9078C24.9396 10.3637 25.25 11.9241 25.25 13.5'
        stroke='#fff'
        stroke-width='3'
      />
    </svg>
  );
};

export default Spinner;
