import baseApi from "../../../Lib/api/baseApi";
import endpoints from "../../../Lib/api/endpoints";

export const authApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    signup: builder.mutation({
      query: (details) => ({
        ...endpoints.completeAdminReg,
        data: details,
      }),
    }),
    login: builder.mutation({
      query: (details) => ({
        ...endpoints.login,
        data: details,
      }),
    }),
    createNewAdmin: builder.mutation({
      query: (details) => ({
        ...endpoints.createNewAdmin,
        data: details,
      }),
    }),
    validateToken: builder.mutation({
      query: (details) => ({
        ...endpoints.validateToken,
        data: details,
      }),
    }),
    forgotPassword: builder.mutation({
      query: (details) => ({
        ...endpoints.forgotPassword,
        data: details,
      }),
    }),
    verifyOtp: builder.mutation({
      query: (details) => ({
        ...endpoints.verifyOtp,
        data: details,
      }),
    }),
    resendOtp: builder.mutation({
      query: (details) => ({
        ...endpoints.resendOtp,
        data: details,
      }),
    }),
    resetPassword: builder.mutation({
      query: (details) => ({
        ...endpoints.resetPassword,
        data: details,
      }),
    }),
  }),
});

export const {
  useSignupMutation,
  useLoginMutation,
  useCreateNewAdminMutation,
  useValidateTokenMutation,
  useForgotPasswordMutation,
  useVerifyOtpMutation,
  useResendOtpMutation,
  useResetPasswordMutation,
} = authApi;
