import React from "react";
import { RouterProvider } from "react-router-dom";
import { Authenticatedroutes, UnAuthenticatedRoutes } from "./privateRoutes";
import { Toaster } from "react-hot-toast";

import { useSelector } from "react-redux";
const Routes = () => {
  const isLoggedIn = useSelector((state) => state.auth.loggedIn);
  return (
    <>
      <RouterProvider
        router={isLoggedIn ? Authenticatedroutes : UnAuthenticatedRoutes}
      />
      <Toaster position='top-center' reverseOrder={false} />
    </>
  );
};

export default Routes;
