import React from "react";

const AccountIcon = ({ color = "#BDBDBD" }) => {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M6.125 1C4.95139 1 4 1.88144 4 2.96875V20.0312C4 21.1185 4.95139 22 6.125 22H18.875C20.0486 22 21 21.1185 21 20.0312V2.96875C21 1.88144 20.0486 1 18.875 1H6.125ZM15.8343 6.71403C16.1108 6.45776 16.1108 6.04224 15.8343 5.78597C15.5576 5.52967 15.1091 5.52967 14.8324 5.78597L9.1658 11.0359C8.88917 11.2922 8.88917 11.7078 9.1658 11.9641C9.44242 12.2203 9.89092 12.2203 10.1676 11.9641L15.8343 6.71403ZM11.7917 6.90625C11.7917 7.63112 11.1574 8.21875 10.375 8.21875C9.5926 8.21875 8.95833 7.63112 8.95833 6.90625C8.95833 6.18138 9.5926 5.59375 10.375 5.59375C11.1574 5.59375 11.7917 6.18138 11.7917 6.90625ZM14.625 12.1562C15.4074 12.1562 16.0417 11.5686 16.0417 10.8438C16.0417 10.1189 15.4074 9.53125 14.625 9.53125C13.8426 9.53125 13.2083 10.1189 13.2083 10.8438C13.2083 11.5686 13.8426 12.1562 14.625 12.1562ZM7.54167 15.4375C7.54167 15.0751 7.8588 14.7812 8.25 14.7812H16.75C17.1411 14.7812 17.4583 15.0751 17.4583 15.4375C17.4583 15.7999 17.1411 16.0938 16.75 16.0938H8.25C7.8588 16.0938 7.54167 15.7999 7.54167 15.4375ZM8.25 17.4062C7.8588 17.4062 7.54167 17.7001 7.54167 18.0625C7.54167 18.4249 7.8588 18.7188 8.25 18.7188H16.75C17.1411 18.7188 17.4583 18.4249 17.4583 18.0625C17.4583 17.7001 17.1411 17.4062 16.75 17.4062H8.25Z'
        fill={color}
      />
    </svg>
  );
};

export default AccountIcon;
