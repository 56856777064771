import React from "react";

const Pagination = ({ total, limit, page, setPage }) => {
  const totalPages = Math.ceil(total / limit);
  return (
    <div className='flex justify-between items-center my-5 mx-2 text-sm'>
      <div>
        Page {page} of {totalPages}
      </div>
      <div className='flex items-center space-x-2 font-medium'>
        <button
          disabled={page === 1}
          className='disabled:text-primaryAsh disabled:cursor-not-allowed text-primaryBlack'
          onClick={() => setPage((prev) => prev - 1)}
        >
          Previous
        </button>
        <p className='bg-primaryGreen px-2 py-1 rounded-md text-white font-semibold '>
          {page}
        </p>
        <button
          disabled={totalPages === 0 || page === totalPages}
          className='disabled:text-primaryAsh disabled:cursor-not-allowed text-primaryBlack'
          onClick={() => setPage((prev) => prev + 1)}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default Pagination;
