import baseApi from "../../../Lib/api/baseApi";
import endpoints from "../../../Lib/api/endpoints";

export const OrderApi = baseApi.injectEndpoints({
  tagTypes: ["Orders"],
  endpoints: (builder) => ({
    getAllOrders: builder.query({
      query: (data) => ({
        ...endpoints.getAllOrders(data),
      }),
      transformResponse: (response) => {
        const transformedData =
          response?.data?.data?.map((d, i) => ({
            no: i + 1,
            customerName: d.customerName,
            amount: d.amount,
            customerPhone: d?.customerPhoneNumber,
            status: d?.currentStatus,
            item: d?.item,
            id: d.id,
          })) || [];

        const page = response?.data?.page || 0;
        const total = response?.data?.total || 0;
        const limit = response?.data?.limit || 0;
        return {
          data: transformedData,
          page: page,
          total: total,
          limit: limit,
        };
      },
      providesTags: (result) =>
        result
          ? [{ type: "Orders", id: "LIST" }]
          : [{ type: "Orders", id: "LIST" }],
    }),
    getIndividualOrder: builder.query({
      query: (id) => ({
        ...endpoints.getIndividualOrder(id),
      }),
      transformResponse: (response) => {
        return response;
      },
    }),
  }),
});

export const { useGetAllOrdersQuery, useGetIndividualOrderQuery } = OrderApi;
