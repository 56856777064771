import React, { useEffect, useState } from "react";
import { images } from "../Assets";
import HamburgerIcon from "../Assets/hamburgerIcon";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "./ui/dropDownMenu";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../Lib/slices/authSlice";
import { rolesTransform } from "../Lib/utils";

function getTimeIn12HourFormatGMT1() {
  const date = new Date();
  const options = {
    timeZone: "Africa/Lagos",
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
  };

  return date.toLocaleString("en-US", options);
}

export default function Navbar() {
  const dispatch = useDispatch();
  const { firstName, lastName, roleId } = useSelector(
    (state) => state.auth.data
  );
  const [currentTime, setCurrentTime] = useState("");

  const links = [
    { name: "Dashboard", to: "/dashboard" },
    { name: "Orders", to: "/orders" },
    { name: "Riders", to: "/riders" },
    { name: "Accounts", to: "/accounts" },
    { name: "Users", to: "/users" },
    { name: "Audit", to: "/audit" },
  ];

  const NavLinks = ({ children }) => {
    return (
      <div>
        <DropdownMenu>
          <DropdownMenuTrigger>{children}</DropdownMenuTrigger>
          <DropdownMenuContent className='w-300px mr-4 bg-[#f5fef1]'>
            <DropdownMenuSeparator />
            {links.map((path) => (
              <DropdownMenuItem key={path.name}>
                <Link
                  className='border w-full rounded-md border-primaryGreen px-2 py-1'
                  to={path.to}
                >
                  {path.name}
                </Link>
              </DropdownMenuItem>
            ))}
            <DropdownMenuItem>
              <button
                className=' flex items-centerborder w-full text-white rounded-md bg-primaryGreen px-2 py-1'
                onClick={() => dispatch(logout())}
              >
                Logout
              </button>
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      </div>
    );
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentTime(getTimeIn12HourFormatGMT1());
    }, 60000);
    setCurrentTime(getTimeIn12HourFormatGMT1());

    return () => clearInterval(intervalId);
  }, []);

  return (
    <header className='z-10 py-4 bg-[#f5fef1] fixed w-full md:pl-72 left-0 h-[70px] flex items-center '>
      <div className='flex px-3 py-2 md:hidden justify-between items-center'>
        <Link to='/dashboard' className='w-[20%]'>
          <img
            src={images.nownow}
            className='w-full h-auto'
            alt='nownow rider'
          />
        </Link>
        <p className='text-xl text-primaryGreen font-bold'>{currentTime}</p>
        <NavLinks className='p-2'>
          <HamburgerIcon />
        </NavLinks>
      </div>

      <div className='px-10 w-full hidden md:flex md:justify-between items-center'>
        <p className='text-xl text-primaryGreen font-bold'>{currentTime}</p>
        <div className='flex flex-col'>
          <p className='text-black font-bold'>
            {firstName} {lastName}
          </p>
          <p className='text-primaryAsh text-sm'>{rolesTransform[roleId]}</p>
        </div>
      </div>
    </header>
  );
}
