import React, { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { ErrorMessage, FormikProvider, useFormik } from "formik";
import { ResetPasswordValidationSchema } from "./AuthValidationSchema"; // Import the validation schema
import { EyeNoneIcon, EyeOpenIcon } from "@radix-ui/react-icons";
import { ArrowLeftIcon } from "@radix-ui/react-icons";
import { images } from "../../Assets";
import { useResetPasswordMutation } from "./AuthApi";
import Spinner from "../../Assets/spinner";
import toast from "react-hot-toast";

const ResetPassword = () => {
  const [resetPass] = useResetPasswordMutation();
  const location = useLocation();
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(true);
  const [showConfirmPassword, setShowConfirmPassword] = useState(true);
  const [submitLoading, setSubmitLoading] = useState(false);

  const initialValues = {
    password: "",
    confirmPassword: "",
  };

  const handleResetPassword = async (values) => {
    setSubmitLoading(true);
    try {
      const { data } = await resetPass(values);
      if (data.statusCode === 200) {
        setSubmitLoading(false);
        toast.success(data?.message, { id: "fpwi" });
        navigate("/reset-success");
      }
    } catch (error) {
      setSubmitLoading(false);
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema: ResetPasswordValidationSchema,
    onSubmit: async (values) => {
      await handleResetPassword({
        email: location?.state?.email,
        otp: location?.state?.otp,
        newPassword: values?.password,
      });
    },
  });

  const { handleSubmit, handleChange, handleBlur, values, errors, touched } =
    formik;

  return (
    <FormikProvider value={formik}>
      <form onSubmit={handleSubmit}>
        <div className='h-[100vh]'>
          <div className='md:flex items-center h-full justify-between'>
            <div className='w-[45%] hidden md:block p-10'>
              <img
                src={images.officeSpace}
                className='w-full h-auto rounded-lg'
                alt='nownow rider'
              />
            </div>
            <div className='w-full md:w-[50%] px-10 text-center'>
              <div className='mb-10 flex justify-center'>
                <img
                  src={images.nownow}
                  className='w-[40%] h-auto'
                  alt='nownow rider'
                />
              </div>
              <p className='font-bold text-md mb-10'>Reset Password</p>
              <p className='font-medium text-md text-left mt-5 md:text-center'>
                Please reset your password below
              </p>
              <div className='mt-10 gap-10'>
                <div className='flex flex-col text-left w-full md:w-[70%] mx-auto'>
                  <label
                    htmlFor='password'
                    className='font-medium mb-1 text-xs'
                  >
                    Password
                  </label>
                  <div className='flex items-center justify-between bg-transparent border border-[#C2C7D6] py-2 px-1 pr-2 rounded-md'>
                    <div className='w-[80%]'>
                      <input
                        id='password'
                        type={!showPassword ? "text" : "password"}
                        className='bg-transparent outline-none'
                        value={values.password}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </div>
                    {!showPassword ? (
                      <button
                        className='w-[10%] p-1'
                        onClick={() => setShowPassword(true)}
                        type='button'
                      >
                        <EyeNoneIcon className='h-4 w-4 cursor-pointer' />
                      </button>
                    ) : (
                      <button
                        className='w-[10%] p-1'
                        onClick={() => setShowPassword(false)}
                        type='button'
                      >
                        <EyeOpenIcon className='h-4 w-4 cursor-pointer' />
                      </button>
                    )}
                  </div>
                  <p className='text-xs text-red'>
                    <ErrorMessage name='password' />
                  </p>
                </div>
                <div className='flex flex-col mt-5 text-left w-full md:w-[70%] mx-auto'>
                  <label
                    htmlFor='confirmPassword'
                    className='font-medium mb-1 text-xs'
                  >
                    Confirm Password
                  </label>
                  <div className='flex items-center justify-between bg-transparent border border-[#C2C7D6] py-2 px-1 pr-2 rounded-md'>
                    <div className='w-[80%]'>
                      <input
                        id='confirmPassword'
                        type={!showConfirmPassword ? "text" : "password"}
                        className='bg-transparent outline-none'
                        value={values.confirmPassword}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </div>
                    {!showConfirmPassword ? (
                      <button
                        className='w-[10%] p-1'
                        onClick={() => setShowConfirmPassword(true)}
                        type='button'
                      >
                        <EyeNoneIcon className='h-4 w-4 cursor-pointer' />
                      </button>
                    ) : (
                      <button
                        className='w-[10%] p-1'
                        onClick={() => setShowConfirmPassword(false)}
                        type='button'
                      >
                        <EyeOpenIcon className='h-4 w-4 cursor-pointer' />
                      </button>
                    )}
                  </div>
                  <p className='text-xs text-red'>
                    <ErrorMessage name='confirmPassword' />
                  </p>
                </div>
              </div>
              <div className='mt-10'>
                <button
                  type='submit'
                  className='bg-primaryGreen flex justify-center mx-auto outline-none text-white w-[50%] py-2 rounded-md'
                  disabled={submitLoading}
                >
                  {submitLoading ? <Spinner /> : "Submit"}
                </button>
              </div>
              <div className='flex items-center justify-center mt-4'>
                <Link to='/login' className='text-black flex items-center'>
                  <ArrowLeftIcon className='h-5 w-5 mr-1 text-primaryGreen' />
                  Back to Sign In
                </Link>
              </div>
            </div>
          </div>
        </div>
      </form>
    </FormikProvider>
  );
};

export default ResetPassword;
