import React, { useState } from "react";
import { images } from "../../Assets";
import { Button } from "../../Components/ui/button";
import { useGetIndividualOrderQuery } from "../Orders/OrderApi";
import { format, parseISO } from "date-fns";
import { SkeletonTable } from "../../Components/ui/skeleton";
import Stepper from "../Dashboard/Stepper";
import { parseMoney, writeClipboardText } from "../../Lib/utils";
import MapComponentBeta from "../../Components/MapComponentBeta";

const OrderDetails = ({ id }) => {
  const { data, isLoading } = useGetIndividualOrderQuery(id);
  const steps = [
    { label: "Order Confirmed", time: "14:00" },
    { label: "Preparing Order", time: "" },
    { label: "Looking for Rider", time: "" },
    { label: "Order Picked", time: "" },
  ];
  return isLoading ? (
    <div className='w-[300px]'>
      <SkeletonTable />
    </div>
  ) : (
    <div className='flex items-center md:h-full justify-center mb-10'>
      <div className='w-full gap-6'>
        {/* {JSON.stringify(data, null, 2)} */}

        <p className='mt-5 mb-2 font-medium'>
          Order Date and Time :{" "}
          {format(parseISO(data?.data?.createdAt), "p, MMM d, yyyy")}
        </p>
        <div className='flex items-center space-x-2 text-primaryBlack'>
          <p className='font-semibold'>Order Amount:</p>
          <p>{parseMoney(data?.data?.amount)}</p>
        </div>
        <div className='flex items-center space-x-2 text-primaryBlack'>
          <p className='font-semibold'>Rider Amount:</p>
          <p>{parseMoney(data?.data?.riderFee)}</p>
        </div>
        <div className='flex items-center space-x-2 text-primaryBlack'>
          <p className='font-semibold'>Vendor Fee:</p>
          <p>{parseMoney(data?.data?.vendorFee ?? 0)}</p>
        </div>
        <div className='flex items-center space-x-2 text-primaryBlack'>
          <p className='font-semibold'>Service Fee:</p>
          <p>{parseMoney(data?.data?.serviceFee ?? 0)}</p>
        </div>
        <div className='text-center'>{/* <Stepper steps={steps} /> */}</div>
        <img className='w-full mt-2' src={images.line77} alt='line77' />
        <div className='flex items-center space-x-2 text-primaryBlack'>
          <p className='font-semibold'>Delivery Code:</p>
          <Button
            variant='ghost'
            className='lowercase flex items-center text-center gap-1 text-primaryBlack font-bold'
            onClick={() =>
              writeClipboardText(
                "OTP copied successfuly",
                data?.data?.deliveryCode
              )
            }
          >
            {data?.data?.deliveryCode} <img src={images.copy} />
          </Button>
        </div>
        <div className=' bg-gray-300 mt-5 p-2 md:p-5'>
          <div className='flex gap-2'>
            <img src={images.pickup} alt='pickup' />
            <h4 className='text-xs md:text-sm text-black font-semibold mr-2'>
              Pick up:
            </h4>
            <p className=' text-xs md:text-sm text-gray-700 font-normal'>
              {data?.data?.pickupLocation?.address}
            </p>
          </div>
          <div className='ml-[6px]'>
            <img src={images.line167} alt='line167' />
          </div>
          <div className='flex gap-2'>
            <img src={images.dropOff} alt='dropOff' />
            <h4 className='text-xs md:text-sm text-black font-semibold mr-2'>
              Drop off:
            </h4>
            <p className='text-xs md:text-sm text-gray-700 font-normal'>
              {data?.data?.deliveryAddress}
            </p>
          </div>
        </div>

        <div className='mt-10'>
          <p className='mt-5 bg-white px-2 text-black font-semibold'>
            Customer Details
          </p>
          <div className=' border border-gray-300 p-2 rounded-md md:w-[50%]'>
            <p>
              {data?.data?.customerName}: {data?.data?.customerPhoneNumber}
            </p>
          </div>
        </div>

        {data?.data?.extraDeliveryNote && (
          <div className='mt-3'>
            <p className=' bg-white px-2 text-black font-semibold'>
              Delivery Note
            </p>
            <div className=' border border-gray-300 p-2 rounded-md md:w-[100%]'>
              {data?.data?.extraDeliveryNote}
            </div>
          </div>
        )}
        <div className='mt-2'>
          <p className=' bg-white px-2 text-black font-semibold'>
            Rider Details
          </p>
          <div className=' border border-gray-300 p-2 rounded-md md:w-[100%]'>
            <div className='md:flex items-center gap-4'>
              <p>
                {data?.data?.rider?.firstName} {data?.data?.rider?.lastName}
                {data?.data?.rider?.phoneNumber} Order Status:
              </p>
              <Button className='p-2' variant={data?.data?.currentStatus}>
                {data?.data?.currentStatus}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderDetails;
