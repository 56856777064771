import React from "react";
import { Link } from "react-router-dom";
import { images } from "../../Assets";

const ResetPasswordSuccess = () => {
  return (
    <div className='h-[100vh]'>
      <div className='md:flex items-center h-full justify-between'>
        <div className='w-[45%] hidden md:block p-10'>
          <img
            src={images.officeSpace}
            className='w-full h-auto rounded-lg'
            alt='nownow rider'
          />
        </div>
        <div className='w-full md:w-[50%] px-10 text-center'>
          <div className='mb-10 flex justify-center'>
            <img
              src={images.nownow}
              className='w-[40%] h-auto mb-10'
              alt='nownow rider'
            />
          </div>

          <p className='text-2xl font-bold mb-0'>Successful!</p>
          <p className='mb-0 text-md font-medium text-left mt-5 md:text-center'>
            You have successfully reset your password
          </p>
          <div className='mt-10 item-center'>
            <p>
              <Link
                className='bg-primaryGreen mt-10 text-white px-10 py-3 rounded-md'
                to='/login'
              >
                Sign In
              </Link>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPasswordSuccess;
