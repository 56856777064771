import React from "react";

const OrderIcon = ({ color = "#BDBDBD" }) => {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M2.25 16.5H3.375H20.7H21.75C22.2 16.5 22.5 16.2 22.5 15.75C22.5 15.3 22.2 15 21.75 15H21C20.85 10.725 17.925 7.2 14.025 6.225C14.175 5.925 14.25 5.625 14.25 5.25C14.25 3.975 13.275 3 12 3C10.725 3 9.75 3.975 9.75 5.25C9.75 5.625 9.825 5.925 9.975 6.225C6.075 7.2 3.15 10.725 3 15H2.25C1.8 15 1.5 15.3 1.5 15.75C1.5 16.2 1.8 16.5 2.25 16.5Z'
        fill={color}
      />
      <path
        d='M4.125 18L4.725 19.125C4.8 19.35 5.1 19.5 5.4 19.5H18.675C18.975 19.5 19.2 19.35 19.35 19.125L19.95 18H4.125Z'
        fill={color}
      />
    </svg>
  );
};

export default OrderIcon;
