import { cn } from "../../Lib/utils";

function Skeleton({ className, ...props }) {
  return (
    <div
      className={cn("animate-pulse rounded-md bg-primaryAsh/20", className)}
      {...props}
    />
  );
}

function SkeletonTable() {
  return (
    <div className='flex flex-col space-y-3'>
      <Skeleton className='h-[125px] w-full rounded-md' />
      <div className='space-y-2'>
        <Skeleton className='h-4 w-full' />
        <Skeleton className='h-4 w-full' />
      </div>
    </div>
  );
}

export { Skeleton, SkeletonTable };
