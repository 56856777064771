import React from "react";

const RidersIcon = ({ color = "#BDBDBD" }) => {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clip-path='url(#clip0_54_342)'>
        <path
          d='M8.65429 12.8652L7.39948 12.8662L6.99534 13.2099C6.64605 13.5068 6.52394 13.9929 6.6914 14.4196L7.44184 16.3324L7.44152 15.9662C7.44063 14.7697 7.89935 13.6826 8.65429 12.8652Z'
          fill={color}
        />
        <path
          d='M17.032 13.2099L16.6279 12.8662L15.373 12.8652C16.128 13.6826 16.5867 14.7697 16.5858 15.9662L16.5854 16.3324L17.3359 14.4196C17.5034 13.9929 17.3813 13.5068 17.032 13.2099Z'
          fill={color}
        />
        <path
          d='M11.8753 3.70766C12.8992 3.70766 13.7291 2.87767 13.7291 1.85383C13.7291 0.829988 12.8992 0 11.8753 0C10.8515 0 10.0215 0.829988 10.0215 1.85383C10.0215 2.87767 10.8515 3.70766 11.8753 3.70766Z'
          fill={color}
        />
        <path
          d='M12.0062 17.5786C12.0059 17.5786 12.0056 17.5786 12.0053 17.5786C11.3892 17.5791 10.8901 18.079 10.8906 18.6951L10.894 22.8854C10.8945 23.5012 11.3939 24.0001 12.0095 24.0001C12.0099 24.0001 12.0102 24.0001 12.0105 24.0001C12.6266 23.9996 13.1257 23.4997 13.1251 22.8836L13.1218 18.6933C13.1213 18.0774 12.6219 17.5786 12.0062 17.5786Z'
          fill={color}
        />
        <path
          d='M18.2776 10.8502C18.1724 10.4178 17.5071 7.68035 17.408 7.27281C17.3627 7.08673 17.259 6.92011 17.112 6.79747L14.4828 4.60232C14.3735 4.51104 14.2362 4.4616 14.0938 4.46192L9.67649 4.47209C9.5161 4.47245 9.36159 4.53259 9.24326 4.64081C9.2431 4.64096 9.24289 4.64112 9.24273 4.64133L6.40951 7.23642C6.25416 7.37877 6.15396 7.57134 6.1267 7.78023C6.08035 8.13492 5.75035 10.6618 5.7049 11.0101C5.64093 11.5 5.98613 11.9489 6.47599 12.0129C6.72823 12.0457 6.97067 11.9704 7.15727 11.818L10.1281 11.8157C10.2414 12.1455 10.4446 12.4511 10.7275 12.6893C9.40999 13.2035 8.48915 14.4835 8.49036 15.9656L8.49345 19.8597C8.49382 20.2997 8.85097 20.6566 9.29092 20.6566C9.29113 20.6566 9.29134 20.6566 9.29155 20.6566L9.84353 20.6561L9.84196 18.6962C9.84101 17.5028 10.8048 16.5312 12.0052 16.5302H12.0062C13.1985 16.5302 14.1693 17.5003 14.1703 18.6926L14.1719 20.6526L14.7237 20.6522H14.7238C15.1637 20.6519 15.521 20.295 15.5207 19.8541C15.5173 15.6177 15.5204 15.9119 15.5104 15.7472C15.425 14.3369 14.5122 13.1678 13.2753 12.6872C13.2752 12.6872 13.2752 12.6872 13.2752 12.6872C13.5405 12.4631 13.7537 12.1629 13.8733 11.8126L16.919 11.8102C17.1221 11.9429 17.3735 11.9905 17.6198 11.9306C18.0997 11.8138 18.3943 11.3302 18.2776 10.8502ZM12.0008 12.1057C12.0006 12.1057 12.0004 12.1057 12.0001 12.1057C11.4874 12.1057 11.0701 11.6888 11.0697 11.176C11.0692 10.6618 11.485 10.2453 11.9993 10.2449C11.9995 10.2449 11.9997 10.2449 12 10.2449C12.5126 10.2449 12.93 10.6617 12.9304 11.1745C12.9309 11.6875 12.5139 12.1052 12.0008 12.1057ZM13.8722 10.535C13.6065 9.76036 12.8687 9.19275 11.9893 9.19647C11.1336 9.20093 10.3926 9.7516 10.126 10.538L7.57037 10.54C7.78245 8.91629 7.61184 10.2225 7.85831 8.3352L9.51689 6.81603L9.52266 9.43634C10.0793 8.64349 10.9954 8.1485 11.9985 8.14771H12H12.0001C12.8818 8.14776 13.6971 8.5305 14.2599 9.16197L14.2546 6.74226L15.7393 7.98182C15.8798 8.55986 16.2345 10.0192 16.3594 10.533L13.8722 10.535Z'
          fill={color}
        />
      </g>
      <defs>
        <clipPath id='clip0_54_342'>
          <rect width='24' height='24' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
};

export default RidersIcon;
