import { createApi } from "@reduxjs/toolkit/query/react";
import axiosBaseQuery from "./request";

const baseApi = createApi({
  reducerPath: "baseApi",
  baseQuery: axiosBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL,
  }),
  tagTypes: ["User"],
  endpoints: () => ({}),
});

export default baseApi;
