import React from "react";
import { Modal, ModalClose, ModalContent, ModalHeader } from "./modal";
import { Dialog, DialogContent, DialogHeader, DialogClose } from "./dialog";
import { Cross2Icon } from "@radix-ui/react-icons";

const ModalComponent = ({ open, setOpen, title, children }) => {
  return (
    <div>
      <Dialog open={open} onOpenChange={setOpen}>
        <DialogContent className='p-0 min-w-[400px] rounded-md w-[90vw] md:w-auto outline-none bg-white'>
          <DialogHeader>
            <div className='flex items-center justify-between border-b border-[#E1E6ED] p-2'>
              <div />
              <p className='text-fontBlack font-semibold text-lg'>{title}</p>
              <DialogClose asChild>
                <button
                  variant='outline'
                  className='rounded-full p-1 transition-opacity hover:opacity-100 focus:outline-none border-2 border-primaryGreen data-[state=open]:bg-secondary'
                >
                  <Cross2Icon className='h-4 w-4' />
                </button>
              </DialogClose>
            </div>
          </DialogHeader>
          <div className='px-3 max-h-[70vh] overflow-y-auto pb-5 bg-white'>
            {children}
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default ModalComponent;
