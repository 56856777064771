import React, { useState } from "react";
import { DatePickerWithRange } from "../../Components/ui/dateRange";
import { images } from "../../Assets";
import { Button } from "../../Components/ui/button";
import DataTable from "../../Components/ui/dataTable";
import Refresh from "../../Assets/refresh";
import { parseMoney } from "../../Lib/utils";
import { Modal, ModalContent, ModalTrigger } from "../../Components/ui/modal";
import UpdateAccountStatus from "./updateAccountStatus";
import {
  useGetAccountMetricsQuery,
  useGetPeriodicEarningsQuery,
  useGetWalletQuery,
  usePostInitiatePayoutMutation,
} from "./AccountApi";
import { format } from "date-fns";
import { Skeleton, SkeletonTable } from "../../Components/ui/skeleton";
import toast from "react-hot-toast";
import Spinner from "../../Assets/spinner";

const Accounts = () => {
  const today = new Date();
  const [date, setDate] = useState({
    from: today,
    to: today,
  });
  const [page, setPage] = useState(1);

  const [completePayout, { isLoading: loadingPayout }] =
    usePostInitiatePayoutMutation();

  const handlePayout = async (body) => {
    try {
      const data = await completePayout(body);
      if (data?.data?.statusCode === 201) {
        toast.success("Payment Completed", { id: "paymnt" });
        refetch();
      }
    } catch (error) {}
  };

  const { data, isLoading: loading } = useGetAccountMetricsQuery(
    {
      startDate: format(date?.from || new Date(), "yyy-MM-dd"),
      endDate: format(date?.to || new Date(), "yyy-MM-dd"),
    },
    {
      refectchOnFocus: true,
      refetchOnReconnect: true,
      refetchOnMountOrArgChange: true,
    }
  );
  // const { data: periodicData, isLoading } = useGetPeriodicEarningsQuery(
  //   {
  //     startDate: format(date?.from || new Date(), "yyy-MM-dd"),
  //     endDate: format(date?.to || new Date(), "yyy-MM-dd"),
  //   },
  //   {
  //     refectchOnFocus: true,
  //     refetchOnReconnect: true,
  //     refetchOnMountOrArgChange: true,
  //   }
  // );
  const { data: periodicData, isLoading, refetch } = useGetWalletQuery();
  const columns = [
    {
      accessorKey: "riderName",
      header: "Rider Name",
      cell: ({ row }) => {
        const details = `${row.original.rider?.firstName} ${row.original.rider?.lastName}`;
        return <div className=''>{details}</div>;
      },
    },

    {
      accessorKey: "total_earned",
      header: "Amount Due",
      cell: ({ row }) => (
        <div className='font-semibold'>
          {parseMoney(row.original.availableBalance)}
        </div>
      ),
    },
    {
      accessorKey: "accountInfo",
      header: "Account Info",
      cell: ({ row }) => {
        const details = `${
          row.original.rider?.bankInformation?.bankName ?? ""
        } ${row.original.rider?.bankInformation?.accountNumber ?? ""} ${
          row.original.rider?.bankInformation?.accountName ?? ""
        }`;
        return <div className='capitalize w-fit'>{details}</div>;
      },
    },
    {
      id: "action",
      cell: ({ row }) => {
        const details = row.original.availableBalance;
        const body = {
          riderId: row.original.riderId,
          amount: row.original.availableBalance,
          // amount: 100,
          description: "Payout for money earned",
        };
        return (
          details > 0 && (
            <Button
              variant='outline'
              className='outline-none focus:outline-none focus-visible:outline-none px-4'
              onClick={() => handlePayout(body)}
              disabled={loadingPayout}
            >
              {loadingPayout ? <Spinner /> : "Pay Out"}
            </Button>
          )
        );
      },
    },
  ];

  return (
    <div className='bg-white p-4'>
      <div className='flex flex-col gap-2 md:flex-row md:gap-0 justify-between items-center my-4'>
        <h2 className='text-2xl font-bold text-primaryBlack'>
          Account Summary
        </h2>
        <DatePickerWithRange date={date} setDate={setDate} />
      </div>
      {loading ? (
        <SkeletonTable />
      ) : (
        <div class='container mx-auto p-y'>
          <div class='grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-5'>
            <div class='p-4 bg-white rounded shadow flex flex-col justify-between'>
              <h2 class='text-md font-normal'>Total Orders</h2>
              <p class='text-2xl font-bold'>
                {data?.data?.order?.totalValue || 0}
              </p>
            </div>
            <div class='p-4 bg-white rounded shadow flex flex-col justify-between'>
              <h2 class='text-md font-normal'>Payment Received</h2>
              <p class='text-2xl font-bold'>
                {parseMoney(
                  parseInt(data?.data?.paymentReceived?.totalValue)
                ) || 0}
              </p>
            </div>
            <div class='p-4 bg-white rounded shadow flex flex-col justify-between'>
              <h2 class='text-md font-normal'>Vendor Fee</h2>
              <p class='text-2xl font-bold'>
                {parseMoney(parseInt(data?.data?.vendorFee?.totalValue)) || 0}
              </p>
            </div>
            <div class='p-4 bg-white rounded shadow flex flex-col justify-between'>
              <h2 class='text-md font-normal'>Riders Amount</h2>
              <p class='text-2xl font-bold'>
                {parseMoney(parseInt(data?.data?.riderFee?.totalValue)) || 0}
              </p>
            </div>
            <div class='p-4 bg-white rounded shadow flex flex-col justify-between'>
              <h2 class='text-md font-normal'>Service Fee</h2>
              <p class='text-2xl font-bold'>
                {parseMoney(parseInt(data?.data?.serviceFee?.totalValue)) || 0}
              </p>
            </div>
          </div>
        </div>
      )}
      <h2 className='text-2xl font-bold text-primaryBlack my-4 mt-10'>
        Payment Due
      </h2>
      {loading ? (
        <SkeletonTable />
      ) : (
        <div className='max-h-[400px] overflow-y-auto max-w-full overflow-x-auto rounded-md border'>
          <div className='w-full'>
            <DataTable columns={columns} data={periodicData?.data?.data} />
          </div>
        </div>
      )}
    </div>
  );
};

export default Accounts;
