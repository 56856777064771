import { useState, useEffect, useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLazyGetAllRidersQuery } from "../../Pages/Riders/RiderApi";
import { updateAvailableRiders } from "../slices/locationSlice";

export const useScreenWidth = () => {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return screenWidth;
};

export const useUpdateDriverStatus = () => {
  const dispatch = useDispatch();
  const { currentOrders, riderLocation } = useSelector(
    (state) => state.location
  );
  const [drivers, setDrivers] = useState([]);
  const [getRider, { isLoading, data: riders }] = useLazyGetAllRidersQuery();

  const handleDriverStatus = useCallback(async () => {
    await getRider();

    const newRiderOrders = currentOrders?.filter(
      (e) =>
        (e?.requestStatus === "accepted" || e?.requestStatus === "pending") &&
        e?.status !== "delivered" &&
        e?.status !== "cancelled"
    );

    const updatedDrivers = riderLocation?.map((driver) => {
      const matchedRider = riders?.find((rider) => rider.id === driver.riderId);
      const isActive = newRiderOrders?.some(
        (order) => order.riderId === driver.riderId
      );

      return {
        value: driver.riderId,
        label: matchedRider ? matchedRider.name : "Unknown Rider",
        active: isActive ? true : false,
        location: driver?.location,
      };
    });
    dispatch(updateAvailableRiders(updatedDrivers));
    setDrivers(updatedDrivers);
  }, [getRider, currentOrders, riderLocation, riders, dispatch]);

  const memoizedDrivers = useMemo(() => drivers, [drivers]);

  useEffect(() => {
    if (currentOrders?.length || riderLocation?.length) {
      handleDriverStatus();
    }
  }, [currentOrders, riderLocation, handleDriverStatus]);

  return { drivers: memoizedDrivers, isLoading };
};
