import { CommandGroup, CommandItem, CommandList } from "./command";
import { Command as CommandPrimitive } from "cmdk";
import { useState, useEffect } from "react";
import { cn } from "../../Lib/utils";
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";
import { useDispatch } from "react-redux";
import {
  updateDriverLocation,
  updateRidersLocation,
} from "../../Lib/slices/locationSlice";
import { useLazyGetNearestRiderQuery } from "../../Pages/Dashboard/DashboardApi";
// Define the Option type for clarity
/**
 * @typedef {Object} Option
 * @property {string} value
 * @property {string} label
 */

// Define the AutoComplete component props
/**
 * @typedef {Object} AutoCompleteProps
 * @property {Option[]} options
 * @property {string} emptyMessage
 * @property {Option} [value]
 * @property {(value: Option) => void} [onValueChange]
 * @property {boolean} [isLoading]
 * @property {boolean} [disabled]
 * @property {string} [placeholder]
 */

/**
 * AutoComplete component
 * @param {AutoCompleteProps} props
 */
export const AutoComplete = ({
  id,
  handleOnBlur,
  setFieldValue,
  isLoading = false,
}) => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const {
    ready,
    value,
    setValue,
    suggestions: { status, data },
    clearSuggestions,
  } = usePlacesAutocomplete({
    callbackName: "NowNow",
    requestOptions: { componentRestrictions: { country: "ng" } },
    debounce: 300,
  });
  const [getRider, { data: nearestRider }] = useLazyGetNearestRiderQuery();
  const handleSelect = async (address) => {
    setValue(address, false);
    clearSuggestions();
    setOpen(false);
    const results = await getGeocode({ address });
    const { lat, lng } = await getLatLng(results[0]);
    dispatch(updateDriverLocation({ lat, lng }));
    const newAddress = {
      coordinates: {
        longitude: lng,
        latitude: lat,
      },
      address,
    };
    setFieldValue(id, newAddress);
    await getRider({ lat, lng });
  };
  useEffect(() => {
    if (nearestRider) {
      dispatch(updateRidersLocation(nearestRider?.data));
    }
  }, [nearestRider]);
  return (
    <CommandPrimitive>
      <div>
        <input
          value={value}
          className='focus:border-primaryGreen border border-[#C2C7D6] outline-none w-full rounded-md p-[6px] text-sm'
          onBlur={(e) => {
            setOpen(false);
            handleOnBlur(e);
          }}
          id={id}
          onChange={(e) => {
            setOpen(true);
            setValue(e.target.value);
          }}
          autoComplete='off'
        />
      </div>
      <div className='relative mt-1'>
        <div
          className={cn(
            "animate-in fade-in-0 zoom-in-95 absolute top-0 z-10 rounded-xl bg-white outline-none max-h-[200px] overflow-auto w-full",
            open ? "block" : "hidden"
          )}
        >
          <CommandList className='rounded-lg ring-1 ring-slate-200'>
            {status === "OK" && (
              <CommandGroup>
                {data.map(({ place_id, description }) => {
                  return (
                    <CommandItem
                      key={place_id}
                      value={description}
                      onMouseDown={(event) => {
                        event.preventDefault();
                        event.stopPropagation();
                      }}
                      onSelect={() => {
                        handleSelect(description);
                      }}
                      className='flex w-full items-center gap-2 border-b'
                    >
                      {description}
                    </CommandItem>
                  );
                })}
              </CommandGroup>
            )}
          </CommandList>
        </div>
      </div>
    </CommandPrimitive>
  );
};
