import React, { useEffect, useState } from "react";
import { images } from "../../Assets";
import Stepper from "./Stepper";
import { Button } from "../../Components/ui/button";
import { useGetIndividualOrderQuery } from "../Orders/OrderApi";
import { format, parseISO } from "date-fns";
import { SkeletonTable } from "../../Components/ui/skeleton";
import {
  useGetRiderLocationQuery,
  useReassignOrderMutation,
} from "./DashboardApi";
import { writeClipboardText } from "../../Lib/utils";
import MapComponentBeta from "../../Components/MapComponentBeta";
import { Combobox } from "../../Components/ui/combobox";
import { useSelector } from "react-redux";
import Spinner from "../../Assets/spinner";
import toast from "react-hot-toast";

const TrackOrder = ({ id, requestStatus }) => {
  const { data, isLoading } = useGetIndividualOrderQuery(id);
  const [reAssign, { isLoading: reassignLoader }] = useReassignOrderMutation();
  const { availableRiders, driverLocation } = useSelector(
    (state) => state?.location
  );
  const { data: riderLocation, isLoading: riderLoading } =
    useGetRiderLocationQuery(data?.data?.riderId, {
      skip: isLoading,
      pollingInterval: 5000,
      skipPollingIfUnfocused: true,
    });
  const [rider, setRider] = useState("");
  const handleReassignOrder = async () => {
    try {
      const body = {
        orderId: id,
        riderId: rider,
      };
      const { data } = await reAssign(body);
      if (data?.statusCode < 300) {
        toast.success("Rider Reassigned successfuly", { id: "reassign" });
      }
    } catch (error) {}
  };
  const steps = [
    { label: "Order Confirmed", time: "14:00" },
    { label: "Preparing Order", time: "" },
    { label: "Looking for Rider", time: "" },
    { label: "Order Picked", time: "" },
  ];
  return isLoading ? (
    <SkeletonTable />
  ) : (
    <div className='flex items-center md:h-full justify-center mb-10 w-[50vw]'>
      <div className='w-full'>
        {/* {JSON.stringify(requestStatus, null, 2)} */}
        <div
          className=' w-full mb-5  grid grid-cols-1 rounded-md'
          style={{ width: "auto", height: 500 }}
        >
          <MapComponentBeta enRoute={riderLocation} />
        </div>
        <p className='mt-5 mb-2 font-medium'>
          Order Date and Time :{" "}
          {format(parseISO(data?.data?.createdAt), "p, MMM d, yyyy")}
        </p>
        {/* <div className='text-center'>
          <Stepper steps={steps} />
        </div>
        <img className='w-full mt-2' src={images.line77} alt='line77' /> */}
        <div className=' bg-gray-300 mt-5 p-2 md:p-5'>
          <div className='flex gap-2'>
            <img src={images.pickup} alt='pickup' />
            <h4 className='text-xs md:text-sm text-black font-semibold mr-2'>
              Pick up:
            </h4>
            <p className=' text-xs md:text-sm text-gray-700 font-normal'>
              {data?.data?.pickupLocation?.address}
            </p>
          </div>
          <div className='ml-[6px]'>
            <img src={images.line167} alt='line167' />
          </div>
          <div className='flex gap-2'>
            <img src={images.dropOff} alt='dropOff' />
            <h4 className='text-xs md:text-sm text-black font-semibold mr-2'>
              Drop off:
            </h4>
            <p className='text-xs md:text-sm text-gray-700 font-normal'>
              {data?.data?.deliveryAddress}
            </p>
          </div>
        </div>

        <div className='mt-2'>
          <p className='mt-5 bg-white px-2 text-black font-semibold'>
            Customer Details
          </p>
          <div className=' border border-gray-300 p-2 rounded-md md:w-[50%]'>
            <p>
              {data?.data?.customerName}: {data?.data?.customerPhoneNumber}
            </p>
            <div className='flex items-center space-x-2 text-primaryBlack'>
              <p className='font-semibold'>Delivery Code:</p>
              <Button
                variant='ghost'
                className='lowercase flex items-center text-center gap-1 text-primaryBlack font-bold'
                onClick={() =>
                  writeClipboardText(
                    "OTP copied successfuly",
                    data?.data?.deliveryCode
                  )
                }
              >
                {data?.data?.deliveryCode} <img src={images.copy} />
              </Button>
            </div>
          </div>
        </div>
        {data?.data?.item && (
          <div className='mt-2'>
            <p className='mt-5 bg-white px-2 text-black font-semibold'>
              Item Name
            </p>
            <div className=' border border-gray-300 p-2 rounded-md md:w-[50%]'>
              <p>{data?.data?.item}</p>
            </div>
          </div>
        )}
        {data?.data?.extraDeliveryNote && (
          <div className='mt-3'>
            <p className=' bg-white px-2 text-black font-semibold'>
              Delivery Note
            </p>
            <div className=' border border-gray-300 p-2 rounded-md md:w-[50%]'>
              {data?.data?.extraDeliveryNote}
            </div>
          </div>
        )}
        {data?.data && (
          <div className='mt-5'>
            <div className='md:flex items-center gap-4 border border-gray-300 p-2 rounded-md md:w-[50%]'>
              <p>
                {data?.data?.rider?.firstName} {data?.data?.rider?.lastName}
                {data?.data?.rider?.phoneNumber}Order Status:
              </p>
              <Button className='p-2' variant={data?.data?.currentStatus}>
                {data?.data?.currentStatus}
              </Button>
            </div>
            <div className=' border border-gray-300 p-2 rounded-md md:w-[50%] mt-5'>
              <div className='md:flex items-center gap-4'>
                <p>Request Status:</p>
                <Button className='p-2' variant={requestStatus}>
                  {requestStatus}
                </Button>
              </div>
            </div>
            {(requestStatus === "expired" || requestStatus === "declined") && (
              <div className='mt-2'>
                <p className='text-black font-semibold'>Re-assign Rider</p>
                <div className='flex gap-4'>
                  <div className='w-[50%]'>
                    <Combobox
                      frameworks={availableRiders}
                      setValue={(val) => {
                        setRider(val?.value);
                      }}
                      value={rider}
                      placeholder='New Rider'
                    />
                  </div>
                  <Button variant='outline' onClick={handleReassignOrder}>
                    {reassignLoader ? <Spinner /> : "Re-Assign"}
                  </Button>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default TrackOrder;
