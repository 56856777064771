import baseApi from "../../../Lib/api/baseApi";
import endpoints from "../../../Lib/api/endpoints";

export const DashboardApi = baseApi.injectEndpoints({
  tagTypes: ["Dashboard"],
  endpoints: (builder) => ({
    createOrder: builder.mutation({
      query: (details) => ({
        ...endpoints.createOrder,
        data: details,
      }),
      invalidatesTags: [{ type: "Dashboard", id: "REQUEST" }],
    }),
    cancelOrder: builder.mutation({
      query: (id) => ({
        ...endpoints.cancelOrder(id),
      }),
      invalidatesTags: [{ type: "Dashboard", id: "REQUEST" }],
    }),
    reassignOrder: builder.mutation({
      query: (data) => ({
        ...endpoints.reassignOrder,
        data,
      }),
      invalidatesTags: [{ type: "Dashboard", id: "REQUEST" }],
    }),
    getAllRidersRequest: builder.query({
      query: (data) => ({
        ...endpoints.getAllRiderRequest(data),
      }),
      transformResponse: (response) => {
        const transformedData =
          response?.data?.data?.map((d, i) => ({
            no: i + 1,
            customerName: d?.order?.customerName,
            riderAmount: d?.riderFee,
            customerPhoneNumber: d?.order?.customerPhoneNumber,
            status: d?.order?.currentStatus,
            requestStatus: d?.status,
            item: d?.order?.item,
            id: d.id,
            riderId: d?.riderId,
            orderId: d?.orderId,
          })) || [];
        const page = response?.data?.page || 0;
        const total = response?.data?.total || 0;
        const limit = response?.data?.limit || 0;
        return {
          data: transformedData,
          page: page,
          total: total,
          limit: limit,
        };
      },
      providesTags: (result) =>
        result
          ? [{ type: "Dashboard", id: "REQUEST" }]
          : [{ type: "Dashboard", id: "REQUEST" }],
    }),
    getDashboardOrders: builder.query({
      query: (data) => ({
        ...endpoints.getAllOrders(data),
      }),
      transformResponse: (response) => {
        const transformedData =
          response?.data?.data?.map((d, i) => ({
            no: i + 1,
            customerName: d.customerName,
            amount: d.amount,
            rider: `${d?.rider?.firstName || ""} ${d?.rider?.lastName || ""} `,
            riderAmount: d?.riderFee,
            status: d?.currentStatus,
            item: d?.item,
            otp: d?.deliveryCode,
            id: d.id,
          })) || [];
        const page = response?.data?.page || 0;
        const total = response?.data?.total || 0;
        const limit = response?.data?.limit || 0;
        return {
          data: transformedData,
          page: page,
          total: total,
          limit: limit,
        };
      },
      // providesTags: (result) =>
      //   result
      //     ? [{ type: "Dashboard", id: "LIST" }]
      //     : [{ type: "Dashboard", id: "LIST" }],
    }),
    getNearestRider: builder.query({
      query: (data) => ({
        ...endpoints.getNearbyRider(data),
      }),
    }),
    getRiderLocation: builder.query({
      query: (id) => ({
        ...endpoints.getRiderLocation(id),
      }),
      transformResponse: (response) => ({
        lat: response?.data?.latitude,
        lng: response?.data?.longitude,
      }),
    }),
  }),
});

export const {
  useCreateOrderMutation,
  useGetDashboardOrdersQuery,
  useGetAllRidersRequestQuery,
  useLazyGetNearestRiderQuery,
  useGetRiderLocationQuery,
  useCancelOrderMutation,
  useReassignOrderMutation,
} = DashboardApi;
